import React from "react";
import { Modal, Button } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

const HomepageVideoModal = ({ show, handleClose, durationVdo }) => {
  console.log(durationVdo);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer url="https://www.youtube.com/watch?v=ysz5S6PUM-U" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HomepageVideoModal;
