import React from "react";

function SeekBar({ played, duration, setPlayed, falser = true }) {
  const secToHHMMSS = (secs) => {
    const pad = (num) => {
      let resData = num < 10 ? "0" + parseInt(num) : parseInt(num);
      return resData;
    };
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    if (hours === 0) {
      return `${pad(minutes)}:${pad(secs)}`;
    } else if (hours !== 0) {
      return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    }
  };
  var newPlayed = secToHHMMSS(Math.round(played));
  var newDuration = secToHHMMSS(duration);
  return (
    <div>
      {falser && (
        <input
          style={{
            width: "90%",
            margin: "0 5% 0 5%",
            height: "2px",
            borderRadius: "10%",
          }}
          className="slider"
          type="range"
          min={0}
          value={Math.round(played)}
          max={duration}
          onChange={(e) => setPlayed(e.target.value)}
        />
      )}
      <div className="d-flex a-end j-center">
        <span style={{ float: "left", marginLeft: "5%", marginTop: "3%" }}>
          {newPlayed}
        </span>
        /
        <span style={{ float: "right", marginRight: "5%", marginTop: "3%" }}>
          {newDuration}
        </span>
      </div>
    </div>
  );
}

export default SeekBar;
