import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Image,
} from "react-bootstrap";
import HeaderSigned from "../Components/HeaderSigned";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReactPlayer from "react-player";
import SeekBar from "../Components/Seekbar";
import { pdfUrl, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import $ from "jquery";
import { findDOMNode } from "react-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "@mui/icons-material/FastForward";
import ReplayIcon from "@mui/icons-material/Replay";
import LoopIcon from "@mui/icons-material/Loop";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../Components/useDebounce";
import { notFound } from "../constant/imagePath";
import parse from "html-react-parser";
import { MobileView, BrowserView } from "react-device-detect";
import MobileRestrictedComponent from "../Components/MobileRestrictedComponent";
import { toast } from "react-toastify";

// import screenfull from "screenfull"
const Videos = (props) => {
  const { data, handleSelectSection } = props;
  let test = useRef();
  let textAreaRef = useRef();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const userDataRedux = useSelector((state) => state?.authReducer?.user);
  const ReduxApi = useSelector((state) => state?.authReducer);
  // const [data, setData] = useState(selectedData);
  const [position, setPosition] = useState(false);
  const [playState, setPlayState] = useState(false);
  const [resources, setResources] = useState(false);
  const [duration, setDuration] = useState(0.0);
  const [looper, setLooper] = useState(false);
  const [played, setPlayed] = useState(0.0);
  const [playedSeekbar, setPlayedSeekbar] = useState(0.0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [Duration1, setDuration1] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showChapters, setShowChapters] = useState(true);
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [compoRender, setCompoRender] = useState("run");
  const [bufferCheck, setBufferCheck] = useState(false);
  const [videoLoader, setVideoLoader] = useState(false);
  const [hover, setHover] = useState(false);

  // const [selectedChapter, setSelectedChapter] = useState(
  //   data.length > 0 ? data.chapters[0] : []
  // );
  // const [selectedVideo, setSelectedVideo] = useState(
  //   data.chapters[0]?.videos[0]
  // );
  const [forumQuestion, setForumQuestion] = useState("");
  const [questionPostLoader, setQuestionPostLoader] = useState(false);
  const [questionPosted, setQuestionPosted] = useState(false);

  // on change Section
  useEffect(() => {
    if (searchDynamicText !== "") {
      data !== null && setSelectedChapter(data?.chapters[searchChapterIndex]);
      setSearchDynamicText("");
      setSearchDynamicArray([]);
      setSearchChapterIndex(0);
      setNewLeftShow(null);
      setSelectedVideo(undefined);
    } else {
      setNewLeftShow(null);
      data !== null && setSelectedChapter(data?.chapters[0]);
      setSelectedVideo(undefined);
    }
  }, [data]);

  // local Search States
  const [searchChapterText, setSearchChapterText] = useState("");
  const [searchChapterArray, setSearchChapterArray] = useState([]);
  const [searchChapterIndex, setSearchChapterIndex] = useState(0);
  const [newLeftShow, setNewLeftShow] = useState(null);

  const [searchVideoText, setSearchVideoText] = useState("");
  const [searchVideoArray, setSearchVideoArray] = useState([]);
  // console.log("========>>>>>>>", newLeftShow);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log("bufferCheck =====>>>>>>>> bufferCheck", bufferCheck);

  const handleClose = () => {
    setAnchorEl(null);
  };

  $("#download_button").click((e) => {
    e.preventDefault();
  });
  // if (position) {
  //   // $("html").addClass("fullVideo");
  //   $("#myvideo>video").attr("controls", "controls");
  // } else {
  //   // $("html").removeClass("fullVideo");
  //   $("#myvideo>video").removeAttr("controls");
  // }
  // $('.fullscreen').on('click', event => {
  //   if (screenfull.isEnabled) {
  //     screenfull.toggle(event.target);
  //   }
  // })
  const handleSpeed = (event, newSpeed) => {
    console.log(event);
    setPlaybackRate(event);
    handleClose();
  };
  const setPlayBack = () => {
    console.log("*************************************");
    // test.current.playbackRate = playbackRate;
  };

  // setTimeout(() => {
  //   if (hover) {
  //     clearTimeout();
  //     setHover(false);
  //   }
  // }, 5000);
  // document.onkeydown = function (e) {
  //   if (e.keyCode == 123) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
  //     return false;
  //   }
  // };
  // document.addEventListener("contextmenu", function (e) {
  //   e.preventDefault();
  // });
  // const  handleClickFullscreen = () => {
  //     screenfull.request(findDOMNode(player))
  //   }
  var elem = document.getElementById("myvideo");
  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      console.log("first");
      // setPosition(true);
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      console.log("second");

      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      console.log("thir");

      elem.msRequestFullscreen();
    }
  }

  function onFullScreen(e) {
    var isFullscreenNow = document.webkitFullscreenElement !== null;
    console.log("Fullscreen " + isFullscreenNow);

    if (isFullscreenNow == true) {
      $("#myvideo>video").attr("controls", "controls");
    } else {
      $("#myvideo>video").removeAttr("controls");
    }
  }

  document
    ?.getElementById("myvideo")
    ?.addEventListener("webkitfullscreenchange", onFullScreen);
  document
    ?.getElementById("myvideo")
    ?.addEventListener("mozfullscreenchange", onFullScreen);
  document
    ?.getElementById("myvideo")
    ?.addEventListener("fullscreenchange", onFullScreen);

  const yourFunction = (event) => {
    console.log("Your event: ", event);
  };

  // forum states and Api's
  const [showForum, setShowForum] = useState(false);
  const [loadingForum, setLoadingForum] = useState(false);
  const [forumData, setForumData] = useState([]);
  const [newChapterVideoShow, setNewChapterVideoShow] = useState([]);
  const ForumApi = URL("forum/video");

  const getForumData = async () => {
    if (selectedVideo?._id == undefined) {
      return;
    }
    const ForumGetApi = ForumApi + "/" + selectedVideo?._id;

    setLoadingForum(true);
    const response = await Get(ForumGetApi, accessToken, false, dispatch);
    if (response !== undefined && newLeftShow !== null) {
      test.current.seekTo(response?.data?.watchTime?.currentDuration);

      setForumData(response?.data?.data);
    }
    setLoadingForum(false);
  };
  useEffect(() => {
    getForumData();
  }, [selectedVideo]);

  // local Search States
  const [searchDynamicText, setSearchDynamicText] = useState("");
  const debouncedSearchTerm = useDebounce(searchDynamicText, 500);

  const [searchDynamicArray, setSearchDynamicArray] = useState([]);

  const getSearchData = async () => {
    const SearchChapterApi = URL(`cms/chapter/search?q=${searchDynamicText}`);
    setLoadingForum(true);
    const response = await Get(SearchChapterApi, accessToken, false, dispatch);
    if (response !== undefined) {
      setSearchDynamicArray(response?.data?.data);
    }
    setLoadingForum(false);
  };
  useEffect(() => {
    if (searchDynamicText !== "") {
      getSearchData();
    } else {
      setSearchDynamicArray([]);
    }
  }, [debouncedSearchTerm]);

  // const handleRef = (e) => {
  //   if (e !== null) {
  //     test = e;
  //     // console.log(e);
  //     // console.log(e.getDuration());
  //     // e.getCurrentTime
  //     console.log(test.getCurrentTime);

  //     setDuration1(test.getCurrentTime);

  //     console.log(Duration1);
  //   }
  // };

  useEffect(() => {
    setDuration1(test.getCurrentTime);
  }, [compoRender]);
  useEffect(() => {
    // console.log("======>>>>>>>>>>", playedSeekbar);
    setTimeout(() => {
      if (playedSeekbar !== undefined && newLeftShow !== null) {
        test.current.seekTo(playedSeekbar);
      }
    }, 200);

    // test.seekTo(playedSeekbar);
  }, [playedSeekbar]);

  const sendDurationLink = URL("users/video/watch");

  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const sendDurationB = async (item) => {
    if (item === undefined) {
      return;
    }
    setSelectedVideo(item);
    const data = {
      currentDuration: Duration1,
      video: selectedVideo,
    };
    const response = await Post(sendDurationLink, data, header, false);
  };

  useEffect(() => {
    return () => {
      sendDurationB();
    };
  }, []);

  useEffect(() => {
    handleSearchChapter();
  }, [searchChapterText]);

  const handleSearchChapter = () => {
    const filterData =
      data !== null &&
      data?.chapters.filter((x) =>
        x.chapterName.toLowerCase().includes(searchChapterText.toLowerCase())
      );
    setSearchChapterArray(filterData);
  };

  useEffect(() => {
    if (newLeftShow !== null) {
      handleSearchVideo();
    }
  }, [searchVideoText]);

  const handleSearchVideo = () => {
    let filterData = [];
    // if user is on free trial
    if (userDataRedux?.isSubscriptionOn == false) {
      filterData =
        data !== null &&
        selectedChapter.videos.filter((x, i) => {
          return (
            x.name.toLowerCase().includes(searchVideoText.toLowerCase()) &&
            i <= 2
          );
        });
    } else {
      // if not
      filterData =
        data !== null &&
        selectedChapter.videos.filter((x) => {
          return x.name.toLowerCase().includes(searchVideoText.toLowerCase());
        });
    }

    setSearchVideoArray(filterData);
  };

  const handleSelectChapter = (item) => {
    setNewLeftShow(item);
    setSelectedChapter(item);
    setSelectedVideo(item.videos[0]);
  };

  const handleSubmitForum = async (e) => {
    if (!userDataRedux?.isSubscriptionOn) return;

    setQuestionPostLoader(true);
    const formApiLink = URL("forum/video");
    e.preventDefault();
    const data = {
      question: forumQuestion,
      questionTime: Duration1,
      // user: userDataRedux._id,
      video: selectedVideo?._id,
    };
    const response = await Post(formApiLink, data, header);
    if (response !== undefined) {
      toast.success("Thanks, we will answer your question within 48 hours.");
      setForumQuestion("");
      textAreaRef.current.value = "";

      setQuestionPosted(true);
      setTimeout(() => {
        setQuestionPosted(false);
      }, 5000);
    }
    setQuestionPostLoader(false);
  };

  const handleSearchDataSelect = (item) => {
    const chapIndex = item.section.chapters.findIndex((x) => x._id == item._id);
    setSearchChapterIndex(chapIndex);
    handleSelectSection(item.section);
    // setData(item.section);
    // setSelectedChapter(item.section.chapters[chapIndex]);
    // setSelectedVideo(item.section.chapters[chapIndex]?.videos[0]);
    // setSearchDynamicText("");
    // setSearchDynamicArray([]);
  };

  const handleChangeSection = (item) => {
    console.log("run");
    setVideoLoader(false);
    // setPlayState(false)
    sendDurationB(item);
  };
  // console.log("videoLoader", test);

  return (
    <>
      <BrowserView>
        <>
          <HeaderSigned
            setSearchText={setSearchDynamicText}
            searchText={searchDynamicText}
            responseArray={searchDynamicArray}
            handleSelect={handleSearchDataSelect}
            newLeftShow={newLeftShow}
          />

          <Container fluid className="bg_color_videos ">
            <Row>
              {showChapters && (
                <Col md={3} className="bg_white border1">
                  <div className="bnf_main_show">
                    <div className="bnf_heading">
                      {newLeftShow == null ? (
                        <h3 className="mb-4">{data?.sectionName}</h3>
                      ) : (
                        <>
                          <h3 className="mb-4">
                            {selectedChapter?.chapterName}
                          </h3>
                          <div
                            className="backBtnContainer"
                            onClick={() => {
                              setNewLeftShow(null);
                            }}
                          >
                            <ChevronLeftIcon />
                            <p style={{ fontSize: 16 }}>back</p>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="sidebar_search mb-4">
                      {newLeftShow !== null ? (
                        <input
                          type="text"
                          placeholder={`Search ${selectedChapter?.chapterName}`}
                          onChange={(e) => {
                            setSearchVideoText(e.target.value);
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          placeholder={`Search ${data?.sectionName}`}
                          onChange={(e) => {
                            setSearchChapterText(e.target.value);
                          }}
                        />
                      )}

                      <SearchIcon />
                    </div>
                    <div className="sidebar_pages">
                      {searchChapterText !== ""
                        ? searchChapterArray.map((item) => {
                            return (
                              <p
                                className={`mini_p cursorPointer ${
                                  selectedChapter?._id == item?._id &&
                                  "selectedItem"
                                }`}
                                onClick={() => {
                                  handleSelectChapter(item);
                                }}
                              >
                                {item?.chapterName}
                              </p>
                            );
                          })
                        : data !== null &&
                          data?.chapters.length > 0 &&
                          newLeftShow == null &&
                          data?.chapters.map((item, i) => {
                            return (
                              <p
                                className={`mini_p cursorPointer ${
                                  selectedChapter?._id == item?._id &&
                                  "selectedItem"
                                } ${
                                  userDataRedux?.isSubscriptionOn == false &&
                                  data?.sectionName !== "BNF" &&
                                  "free-user"
                                } 
                                
                                ${
                                  userDataRedux?.isSubscriptionOn == false &&
                                  data?.sectionName == "BNF" &&
                                  i > 0 &&
                                  "free-user"
                                }
                                `}
                                onClick={() => {
                                  // disable cond
                                  if (
                                    userDataRedux?.isSubscriptionOn == false &&
                                    data?.sectionName !== "BNF"
                                  )
                                    return;
                                  if (
                                    userDataRedux?.isSubscriptionOn == false &&
                                    data?.sectionName == "BNF" &&
                                    i > 0
                                  )
                                    return;

                                  handleSelectChapter(item);
                                }}
                              >
                                {item?.chapterName}
                              </p>
                            );
                          })}
                      {newLeftShow !== null && (
                        <>
                          {searchVideoText !== ""
                            ? searchVideoArray.length > 0 &&
                              searchVideoArray.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={`mini_p d-flex side_contents mb-4 cursorPointer ${
                                      selectedVideo?._id == item?._id &&
                                      "selectedItem"
                                    }
                                    ${
                                      userDataRedux?.isSubscriptionOn ==
                                        false &&
                                      i > 2 &&
                                      "free-user"
                                    }
                                    `}
                                    onClick={() => {
                                      if (
                                        userDataRedux?.isSubscriptionOn ==
                                          false &&
                                        i > 2
                                      )
                                        return;
                                      sendDurationB(item);
                                    }}
                                  >
                                    <p>{item.name}</p>
                                  </div>
                                );
                              })
                            : selectedChapter.videos.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={`mini_p d-flex side_contents mb-4 cursorPointer ${
                                      selectedVideo?._id == item?._id &&
                                      "selectedItem"
                                    }
                                    ${
                                      userDataRedux?.isSubscriptionOn ==
                                        false &&
                                      i > 2 &&
                                      "free-user"
                                    }
                                    `}
                                    onClick={() => {
                                      if (
                                        userDataRedux?.isSubscriptionOn ==
                                          false &&
                                        i > 2
                                      )
                                        return;
                                      handleChangeSection(item);
                                    }}
                                  >
                                    <p>{item.name}</p>
                                  </div>
                                );
                              })}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              )}

              {newLeftShow !== null && selectedVideo !== undefined ? (
                <>
                  <Col
                    md={showChapters ? 6 : 8}
                    className={
                      position
                        ? "mt-5 video_fullscript onn"
                        : "video_fullscript mt-5"
                    }
                  >
                    <div
                      className={position ? "video_div" : "video_div"}
                      onMouseEnter={() => {
                        setHover(true);
                      }}
                      onMouseOut={() => {
                        setHover(false);
                      }}
                    >
                      <div
                        className={
                          playState ? "video_inner" : "video_inner layer_pic"
                        }
                        onClick={() => {
                          setPlayState(!playState);
                        }}
                      >
                        {/* <button onClick={openFullscreen}>
                          Open Video in Fullscreen Mode
                        </button> */}
                        <ReactPlayer
                          id="myvideo"
                          onContextMenu={(e) => e.preventDefault()}
                          onReady={() => setVideoLoader(true)}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload", //<- this is the important bit
                              },
                            },
                          }}
                          loop={looper}
                          // controls
                          onCanPlay={() => setPlayBack()}
                          ref={test}
                          url={selectedVideo?.link}
                          onPlay={yourFunction}
                          playing={playState}
                          // onStart={test.seekTo(2)}
                          onProgress={(e) => {
                            // console.log(e);
                            setPlayed(e.playedSeconds);
                          }}
                          onDuration={(e) => {
                            setDuration(e);
                          }}
                          onEnded={() => {
                            setPlayState(!playState);
                          }}
                          playbackRate={playbackRate}
                          onSeek={(e) => setCompoRender(Math.random())}
                          onClick={() => setPlayState(!playState)}
                          onBufferEnd={(e) =>
                            console.log(
                              "qwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww",
                              e
                            )
                          }
                        />
                      </div>
                      <div
                        className={`controls_main ${position && "opacity_0"} ${
                          hover && "showing"
                        }`}
                        onMouseOver={(e) => setHover(true)}
                        onMouseOut={(e) => setHover(false)}
                      >
                        <div className="first d-flex">
                          <div onClick={() => setPlayState(!playState)}>
                            {playState ? <PauseIcon /> : <PlayArrowIcon />}
                          </div>
                          <div onClick={() => test?.seekTo(Duration1 + 5)}>
                            <FastForwardIcon />
                          </div>
                          <div onClick={() => test?.seekTo(0)}>
                            <ReplayIcon />
                          </div>
                          <div className="seekbar_width">
                            <SeekBar
                              played={played}
                              duration={duration}
                              setPlayed={setPlayedSeekbar}
                              onClick={(e) => console.log(e)}
                            />
                          </div>
                        </div>
                        <div className="second d-flex">
                          <div onClick={handleClick}>
                            <SettingsIcon />
                          </div>
                          <div onClick={() => setLooper(!looper)}>
                            <LoopIcon />
                          </div>
                          <div
                            className="fullscreen"
                            data-click="1"
                            onClick={
                              () => openFullscreen()
                              // setPosition(!position)
                            }
                          >
                            <FullscreenIcon />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="videos_desc_main ">
                      <h3>{selectedVideo?.description}</h3>
                      <personalbar>{selectedChapter?.chapterName}</personalbar>

                      <div className="d-flex bb-1">
                        {selectedVideo?.preferences !== "" && (
                          <p
                            className={
                              !resources
                                ? "mini_p cursorPointer active_bottom"
                                : "mini_p cursorPointer"
                            }
                            onClick={() => setResources(false)}
                          >
                            REFERENCES
                          </p>
                        )}
                        {selectedVideo?.resources.length > 0 && (
                          <p
                            className={
                              resources
                                ? "mini_p cursorPointer active_bottom"
                                : "mini_p cursorPointer"
                            }
                            onClick={() => setResources(true)}
                          >
                            RESOURCES
                          </p>
                        )}
                      </div>
                      <div>
                        {resources ? (
                          <div className="download_pdf_inner">
                            {selectedVideo?.resources !== undefined &&
                              selectedVideo?.resources.map((Res, i) => (
                                <div>
                                  <p className="mini_p cursorPointer">
                                    {Res?.title}
                                  </p>
                                  <a
                                    download
                                    target="_blank"
                                    href={`${pdfUrl}${Res.pdf}`}
                                    className="btnPdf"
                                  >
                                    <Button className="download_pdf_btn">
                                      PDF
                                    </Button>
                                  </a>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="download_pdf_inner">
                            <p className="mini_p">
                              {parse(selectedVideo?.preferences)}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={showChapters ? 3 : 4} className="mt-5" style={{backgroundColor: '#fafafb !important'}}>
                    <div className="content_main new-scroll-fix">
                      <div className="category_videos_right d-flex heading_space mb-4">
                        {/* <p className="cursorPointer">CONTENTS</p>
                <p className="cursorPointer">FORUM</p> */}

                        <p
                          className={
                            showForum == false
                              ? "cursorPointer active_bottom"
                              : "cursorPointer"
                          }
                          onClick={() => setShowForum(false)}
                        >
                          CONTENTS
                        </p>
                        <p
                          className={
                            showForum
                              ? "cursorPointer active_bottom"
                              : "cursorPointer"
                          }
                          onClick={() => setShowForum(true)}
                        >
                          FORUM
                        </p>
                      </div>
                      {showForum ? (
                        <>
                          <form
                            onSubmit={handleSubmitForum}
                            style={{
                              marginBottom: "20px",
                            }}
                          >
                            <div className="d-flex forum_form">
                              <textarea
                                ref={textAreaRef}
                                className="forum_input"
                                required
                                placeholder="Ask a Question"
                                onChange={(e) =>
                                  setForumQuestion(e.target.value)
                                }
                              />
                              <Button
                                disabled={
                                  questionPostLoader ||
                                  !userDataRedux?.isSubscriptionOn
                                }
                                type="submit"
                                className={
                                  questionPosted
                                    ? "forumSumitBtn green"
                                    : "forumSumitBtn"
                                }
                              >
                                {questionPostLoader
                                  ? "Posting"
                                  : "Ask a Question..."}
                              </Button>
                            </div>
                          </form>
                          {forumData.map((item, index) => {
                            return (
                              <>
                                <div className="d-flex flex-column justify-content-center align-items-start side_contents mb-2 cursorPointer">
                                  <p className="forumTitle">
                                    {item?.user?.name}
                                  </p>
                                  <p className="mini_p forumText">
                                    {item?.question}
                                  </p>
                                </div>
                                {item?.answer !== null && (
                                  <div className="d-flex flex-column justify-content-center align-items-start side_contents mb-4 cursorPointer answerContainer">
                                    <p className="forumTitle">Pre reg Master</p>
                                    <p className="mini_p forumText">
                                      {item?.answer}
                                    </p>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        selectedVideo !== undefined &&
                        selectedVideo?.tags !== undefined &&
                        selectedVideo.tags.map((item, index) => {
                          return (
                            <div className="d-flex side_contents mb-4 cursorPointer">
                              <div>
                                <p
                                  className="blue_numbering"
                                  style={{ marginRight: "30px" }}
                                >
                                  {Number(index + 1)}
                                </p>
                              </div>
                              <p className="font-14 cont_text">{item}</p>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </Col>
                </>
              ) : newLeftShow == null ? (
                <p></p>
              ) : (
                <div className="no-content-container">
                  <Image className="no-content-image" src={notFound} />
                  <p>No Content Found!</p>
                </div>
              )}
            </Row>
          </Container>
          <Menu
            id="basic-menu_video_settings"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              orientation="vertical"
              value={playbackRate}
              exclusive
              onChange={handleSpeed}
            >
              <div
                value={0.5}
                onClick={() => handleSpeed(0.5)}
                aria-label="list"
              >
                0.5x
              </div>
              <div value={1} onClick={() => handleSpeed(1)} aria-label="module">
                1x
              </div>
              <div
                value={1}
                onClick={() => handleSpeed(1.5)}
                aria-label="module"
              >
                1.5x
              </div>
              <div
                value={2.0}
                onClick={() => handleSpeed(2)}
                aria-label="quilt"
              >
                2x
              </div>
            </div>
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </Menu>
        </>
      </BrowserView>
      <MobileView>
        <MobileRestrictedComponent />
      </MobileView>
    </>
  );
};

export default Videos;
