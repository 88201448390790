import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import { apiUrl, URL } from "../Config/apiUrl";
import { signOutRequest, updateUserData } from "../store/Actions/authAction";
import { useHistory } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import logo from "../assets/Images/logo.png";
import { toast } from "react-toastify";

const Thankyou = () => {
  const token = useSelector((state) => state?.authReducer?.access_token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [responseData, setResponseData] = useState(null);

  const [isApiCall, setISApiCall] = useState(true);

  const getUserData = async () => {
    const _cmbopu = localStorage.getItem("_cmbopu");
    console.log(_cmbopu);
    if (!_cmbopu) {
      return history.replace("/");
    }
    const mainUrl = URL(
      `product/redirect/callback/klarna${window.location.search}&${_cmbopu}`
    );
    let params = new URLSearchParams(mainUrl);
    let redirect_status = params.get("redirect_status");

    // if we have an error
    if (redirect_status == null || redirect_status == "failed") {
      toast.error("Task failed. Please try again later.");
      history.push("/packages");
      // setISApiCall(false);
      return;
    }

    const response = await Get(mainUrl, token);
    if (response !== undefined) {
      setResponseData(response?.data?.data);
      localStorage.removeItem("_cmbopu");
      setTimeout(() => {
        updateUser(response?.data?.data);
      }, 2000);
      setISApiCall(false);
    } else {
      setISApiCall(false);
      dispatch(signOutRequest());
      history.push("/");
    }
  };

  const updateUser = (response) => {
    console.log(response, response);
    dispatch(updateUserData(response));
    history.replace("/dashboard");
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleThankYou = (e) => {
    e.preventDefault();
    updateUser(responseData);
  };

  return (
    <Container className="nav_cont thankyou--master">
      <div className="thankyou-container last_sec-bg">
        <Navbar.Brand href="#" className="font-24 blue logo_main">
          PRE REG MASTER
        </Navbar.Brand>
        <Navbar.Brand href="#" className="font-24 blue logo_main">
          <img src={logo} />
        </Navbar.Brand>
      </div>
      {!isApiCall && (
        <div className="thankyou-inner">
          <h1>Congratulations</h1>
          <p>
            You have successfully unlocked a complete programme focusing on the
            most examinable content!
          </p>
          <p className="thankyou-wait">Please hold on a moment!</p>
        </div>
      )}

      {isApiCall && (
        <div className="thankyou-inner">
          <h1>Please Hold on a moment</h1>
          <p className="thankyou-wait">You will be redirected shortly!</p>
        </div>
      )}
    </Container>
  );
};

export default Thankyou;
