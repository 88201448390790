import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import HeaderSigned from "../Components/HeaderSigned";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchInput from "../Components/SearchInput";
import { support1, support2, support3, support4 } from "../constant/imagePath";
import { imageUrl, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import parse from "html-react-parser";

import { saveSectionData } from "../store/Actions/authAction";
import { BrowserView, MobileView } from "react-device-detect";
import MobileRestrictedComponent from "../Components/MobileRestrictedComponent";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const userData = useSelector((state) => state?.authReducer?.user);

  //                                     package user      free user
  const ApiURLNew = URL(
    userData?.isSubscriptionOn ? `cms/section` : "cms/section/freeTrial"
  );

  const getSectionData = async () => {
    const responseData = await Get(ApiURLNew, accessToken, true, dispatch);
    if (responseData !== undefined) {
      dispatch(saveSectionData(responseData?.data?.data));
    }
  };
  //api Header And Url
  const ApiURL = URL(`cms/pages?page=web_dashboard&all=${false}`);
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  // ==== getPageData ===
  const getPageData = async () => {
    setBlurLoader(true);
    const responseData = await Get(ApiURL, accessToken, true, dispatch);
    if (responseData !== undefined) {
      setDataList(responseData?.data?.data);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getPageData();
    getSectionData();
  }, []);

  return (
    <>
      <BrowserView>
        <div className={blurLoader ? "pageBlur" : ""}>
          <HeaderSigned hide={true} videoHide={false} />
          <section className="dashboardSection">
            <Container fluid>
              <Row>
                <Col md={12} className="dashboardHeadingContainer">
                  <div className="mt-4 mb-4">
                    <h4 className="vbold">{dataList?.pages?.heading}</h4>
                    {/* <h4>Welcome to the Pre Reg Master Course!</h4> */}
                  </div>
                </Col>
              </Row>
            </Container>

            <Container fluid>
              <Row>
                <Col md={5}>
                  <div className="dashboardContantContiner1 mb-5">
                    {dataList?.pages?.content_1 != undefined &&
                      parse(dataList?.pages?.content_1)}
                    {/* <p>
                  Refer your friends to the dashboard and get €50 EVERY TIME*
                  Subject: £50 Referral Claim Body: I have referred my friend
                  (FULL NAME] The email address they used to sign up is (EMAIL|
                  My bank details are: NAME] /SORT CODE [ACCOUNT NUMBER] Once
                  the technical team has verified the details the accounts team
                  will make a transfer. This process usually takes 7-10 days.
                  *Claims must be sent within 3 days of sign up.
                </p> */}
                  </div>
                </Col>
                <Col md={5}>
                  <div className="dashboardContantContiner2 mb-5">
                    {dataList?.pages?.content_2 != undefined &&
                      parse(dataList?.pages?.content_2)}

                    {/* <p>
                  Claim your FREE copy of the Pre Reg Master App Those who
                  signed up to a 6 month or 12 month package are entitled to a
                  free 1 year subscription of the Pre Reg Master App The app
                  contains over 500 GPhC style questions with detailed
                  explanations. 1) Download and sign up on the app 2) Email us
                  at support@preregmaster.com with the following details:
                  subject: FREE App Claim Body: [Email address used to sign up
                  on the Appl [Email address used to sign up on the Dashboard)
                </p> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </BrowserView>
      <MobileView>
        <MobileRestrictedComponent />
      </MobileView>
    </>
  );
};

export default Dashboard;
