import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import Videos from "../Screens/Videos";
import Support from "../Screens/Support";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useHistory,
} from "react-router-dom";
import Signin from "../Screens/Signin";
import Signup from "../Screens/Signup";
import {
  home_icon,
  logo,
  logoNew,
  thirdIcon,
  thirdIcon1,
  thirdIcon1Colored,
  thirdIcon2,
  thirdIcon3,
  thirdIcon4,
  thirdIcon5,
} from "../constant/imagePath";
import { imageUrl, URL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import Dashboard from "../Screens/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import { BrowserView } from "react-device-detect";
const SidebarCus = ({ selected, setSelected }) => {
  const history = useHistory();
  const token = useSelector((state) => state?.authReducer?.access_token);
  const sectionData = useSelector((state) => state?.authReducer?.section);
  const userData = useSelector((state) => state?.authReducer?.user);
  // const [selected, setSelected] = useState(null);
  // const [selected, setSelected] = useState(sectionData[0]);
  const handleSelectSection = (item) => {
    setSelected(item);
  };
  return (
    <>
      {/* Free */}
      {userData?.isSubscriptionOn == false && (
        <div className={"free-div"}>
          <p>{`Welcome to your free trial. We have unlocked 3 FREE videos for you in the BNF > GI section`}</p>
          <span onClick={() => history.push("/packages")}>
            UNLOCK ALL VIDEOS
          </span>
        </div>
      )}
      <div className={"cus_sidebar_wrapper"}>
        <BrowserView>
          <div className="cus_sidebar_main">
            <div className=" sidebar_items sidebar_logo">
              <Image src={logoNew} />
              {/* <Image src={logo} /> */}
            </div>
            <div
              className={`sidebar_items ${selected == null && "activeSidebar"}`}
              onClick={() => {
                setSelected(null);
                history.push("/dashboard");
              }}
            >
              <Image
                src={home_icon}
                className={`${selected == null && "activeImage"}`}
              />
              HOME
            </div>

            {sectionData?.length > 0 &&
              sectionData.map((e, i) => (
                <div
                  className={`sidebar_items ${
                    e?.sectionName == selected?.sectionName && "activeSidebar"
                  }`}
                  onClick={() => {
                    setSelected(e);
                    history.push("/Videos");
                  }}
                >
                  <Image
                    src={`${imageUrl}${e?.photo}`}
                    className={`${
                      e?.sectionName == selected?.sectionName && "activeImage"
                    }`}
                  />
                  {e?.sectionName}
                </div>
              ))}
          </div>
        </BrowserView>

        {/* <Router> */}
        <Switch>
          <div className="sidebar_screen_main">
            <ProtectedRoute exact path="/dashboard">
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute exact path="/Videos">
              <Videos
                data={selected}
                // data={selected == null ? [] : selected}
                // data={selected == null ? [{ chapters: [] }] : selected}
                handleSelectSection={handleSelectSection}
              />
            </ProtectedRoute>

            <ProtectedRoute exact path="/Support">
              <Support />
            </ProtectedRoute>
          </div>
        </Switch>

        {/* </Router> */}
      </div>
      <div className="text-center ShowMedia">
        <h1>Screenshot function is prevented. Your attempt has been logged</h1>
      </div>
    </>
  );
};

export default SidebarCus;
