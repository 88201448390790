import React, { useEffect, useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import classes from "./StripeCardField.module.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { toast } from "react-toastify";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: 20,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const StripeCardField = (props) => {
  const { AttachCardHandler, loading } = props;
  const stripe = useStripe();
  const [name, setName] = useState("");
  const elements = useElements();
  const options = useOptions();

  const onSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    // card number element as the card element
    const cardNumberElement = elements?.getElement(CardNumberElement);

    if (cardNumberElement) {
      const { error, paymentMethod } = await stripe?.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: name,
        },
      });

      if (!error && paymentMethod?.id) {
        AttachCardHandler(paymentMethod.id);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container className={classes.MainContainer}>
      <Row>
        <Col md={12}>
          <div className={classes.fieldContainer}>
            <span>Card number</span>
            <CardNumberElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className={classes.fieldContainer}>
            <span>Card Holder name</span>
            <input
              className={classes.nameInput}
              value={name}
              placeholder={"Enter Card Holder Name"}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className={classes.fieldContainer}>
            <span>Expiry Date</span>
            <CardExpiryElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className={classes.fieldContainer}>
            <span>CVC</span>
            <CardCvcElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <div
            className={[classes.btnMainDiv, classes.cardBtn].join(" ")}
            onClick={() => loading == false && stripe && onSubmit()}
          >
            <BiPurchaseTagAlt size={20} color={"#3792ec"} />
            <span>{loading ? "Purchasing..." : "Purchase Package"}</span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StripeCardField;
