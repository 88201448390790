// export const apiUrl = "https://www.backend.preregmaster.co.uk"; // main
export const apiUrl = "https://pre-reg-master-backend-dem-e6a6ac3b0732.herokuapp.com"; // main
// export const apiUrl =
//   "https://pre-reg-master-copy-backend-abe98a68089a.herokuapp.com"; // copy
// export const apiUrl = "https://9d8f-39-57-196-160.ngrok-free.app";
export const imageUrl = `${apiUrl}/api/images/`;
export const pdfUrl = `${apiUrl}/api/pdf/`;

export const URL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

// export const stripe_public_key =
//   "pk_test_51K1wPxCVf0eQsmps75coJ2cygqrnpbCy57wojyW7kDQR1GRIVBvGiYDyqGTK96QQzwBaG708KJpfVPby00BVUHbP00gz7CUbKj";
// export const stripe_public_key =
//   "pk_test_51MyXHsHR1dKHM2xvZM0x2aiu3ENVcQclg97vz2Bg3kook363v0ReTj7gIWHrOogLveuStCR02v92hHBZvbWFekFG00ii7doE7q";

// ali Live
export const stripe_public_key =
  "pk_live_51K1wPxCVf0eQsmpsnict4DRFXNgasFt3E8K3WYuehRLGBgigOHO8ABxP1tx7tWxO3I3NcayOieIJVXcJqEQ70klK00gmmgwgYv";
// export const stripe_public_key =
//   "pk_test_51K1wPxCVf0eQsmps75coJ2cygqrnpbCy57wojyW7kDQR1GRIVBvGiYDyqGTK96QQzwBaG708KJpfVPby00BVUHbP00gz7CUbKj";

export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
