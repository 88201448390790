import React, { useEffect, useState } from "react";
import SidebarCus from "../Components/SidebarCus";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  bowl,
  firstSection,
  second_section_left,
  tag,
  thirdIcon1,
  thirdIcon2,
  thirdIcon3,
  thirdIcon4,
  thirdSection_right,
  klarnaImg,
  user,
} from "../constant/imagePath";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import PaymentMethodModal from "../Components/PaymentMethodModal";
import { useDispatch, useSelector } from "react-redux";
import PackageHeader from "../Components/PackageHeader";
import { stripe_public_key, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { updateUserData } from "../store/Actions/authAction";
import { toast } from "react-toastify";
import Footer from "../Components/Footer";
import { SpinnerRoundOutlined } from "spinners-react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentModal from "../Components/PaymentModal";

const PackageScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const stripePromise = loadStripe(stripe_public_key);

  const userData = useSelector((state) => state?.authReducer?.user);
  const token = useSelector((state) => state?.authReducer?.access_token);

  const [packageId, setPackageId] = useState(null);
  const [packagesArray, setPackagesArray] = useState([]);

  const [packageamountforApplePay, setPackageAmountForApplePay] = useState(0);
  const [ourNewTotalAmount, setOurNewTotalAmount] = useState(0);
  const [packageAmount, setPackageAmount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [buyPackageLoading, setBuyPackageLoading] = useState(false);

  const handleClose = () => setPackageId(null);
  const handleShow = (data, unit_amount_decimal) => {
    setPackageId(data);
    // console.log({ unit_amount_decimal });
    setPackageAmountForApplePay(unit_amount_decimal);
    setPackageAmount(unit_amount_decimal);
    setOurNewTotalAmount(unit_amount_decimal);
  };

  const array0 = [
    {
      icon: <DoneIcon />,
      p: "BNF",
      li: "Exam focussed",
    },
    {
      icon: <DoneIcon />,
      p: "Over The Counter",
      li: "Top 50 conditions",
    },
    {
      icon: <DoneIcon />,
      p: "Law",
      li: "Rules & regulations",
    },
    {
      icon: <DoneIcon />,
      p: "Calculations",
      li: "Worked examples",
    },
    // {
    //   icon: <DoneIcon />,
    //   p: "Mock Exam",
    //   li: "April 2022",
    // },
    {
      icon: <CloseIcon style={{ color: "red" }} />,
      p: "App NOT included",
      li: "500+ questions",
    },
  ];
  const array = [
    {
      icon: <DoneIcon />,
      p: "BNF",
      li: "Exam focussed",
    },
    {
      icon: <DoneIcon />,
      p: "Over The Counter",
      li: "Top 50 conditions",
    },
    {
      icon: <DoneIcon />,
      p: "Law",
      li: "Rules & regulations",
    },
    {
      icon: <DoneIcon />,
      p: "Calculations",
      li: "Worked examples",
    },
    // {
    //   icon: <DoneIcon />,
    //   p: "Mock Exam",
    //   li: "April 2022",
    // },
    {
      icon: <CloseIcon style={{ color: "red" }} />,
      p: "App NOT included",
      li: "500+ questions",
    },
  ];
  const array6month = [
    {
      icon: <DoneIcon />,
      p: "Dashboard",
      li: ["Over 170 Videos", "Clinical, OTC, Law & Calcs"],
    },
    {
      icon: <DoneIcon />,
      p: "App",
      li: ["OVER 1000 QUESTIONS"],
    },
    {
      icon: <DoneIcon />,
      p: "One Mock",
      li: ["Two Part Exam", "Clinical & Calcs"],
    },
  ];

  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const getPlansApiUrl = URL("product/plans");
  const getPlans = async () => {
    setLoading(true);
    const responseData = await Get(getPlansApiUrl, token, true, dispatch);
    if (responseData !== undefined) {
      let data = [];
      responseData?.data?.data?.map((item) => {
        // if (item?.product?.unit_label == "month-12") {
        //   data[0] = {
        //     ...item,
        //     content: array0,
        //   };
        // }
        // else if (item?.product?.unit_label == "month-3") {
        //   data[1] = {
        //     ...item,
        //     content: array,
        //   };
        // } else if (item?.product?.unit_label == "month-6") {
        //   data[2] = {
        //     ...item,
        //     content: array6month,
        //   };
        // }
        if (item?.product?.unit_label == "month-12") {
          data[3] = {
            ...item,
            content: array6month,
          };
        }
      });
      setPackagesArray(data);
    }
    setLoading(false);
  };

  // pay with card
  const HandleBuyPackage = async (
    id,
    voucher
    // isMobileAppTaken,
    // isMockExamTaken,
    // mockupLength,
    // addonAmount
  ) => {
    const buyPackageApiUrl = URL("users/create-subscription");
    setBuyPackageLoading(true);
    const params = {
      paymentMethodId: id,
      priceId: packageId,
      // ! new property
      // isMobileAppTaken,
      // isMockExamTaken,
      // mockupLength,
      // ourNewTotalAmount,
      // addonAmount,
      // amount: ourNewTotalAmount,
      ...(voucher !== "" && { voucherCode: voucher }),
    };

    const responseData = await Post(buyPackageApiUrl, params, header);

    if (responseData !== undefined) {
      let resData = responseData?.data?.data;
      if (resData?.status === "requires_action") {
        if (resData?.next_action?.redirect_to_url?.url) {
          const threeDParams = {
            app: resData?.app,
            exam: resData?.exam,
          };
          localStorage.setItem("_xpxpx", JSON.stringify(threeDParams));
          localStorage.setItem("_xp__pc__id", packageId);
          return window.open(resData?.next_action?.redirect_to_url?.url);
        }
      }
      dispatch(updateUserData(responseData?.data?.data));
      toast.success("Your purchase is successful!.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    setBuyPackageLoading(false);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div>
      <PackageHeader />

      <div className="last_sec_bg">
        {loading ? (
          <div className="col-md-12 loaderContainer">
            <SpinnerRoundOutlined
              enabled={loading}
              thickness={200}
              color="#3090EF"
            />
          </div>
        ) : (
          <Container className="last_section">
            <Row>
              <Col className="p_colors text-center mb-5 mt-5">
                {!user?.isSubscriptionOn && (
                  <div
                    className="text-left"
                    style={{
                      marginLeft: "20%",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("/dashboard")}
                  >
                    <IconButton>
                      <ArrowBackIcon />
                    </IconButton>
                    <span>Back to Dashboard</span>
                  </div>
                )}
                <h4>Pricing</h4>
                <p>
                  All of our packages include access to the support dashboard.
                  Feel free to ask us anything there.
                </p>
                <p>The dashboard is only supported on desktop devices.</p>
                <img src={klarnaImg} />
              </Col>
            </Row>
            <Row className="row_flip">
              {/* <Col lg={2} md={12} sm={12}></Col> */}
              {packagesArray.map((e, i) => {
                return (
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    className="pricing_table"
                    onClick={() => {
                      handleShow(e?.id, e?.unit_amount / 100);
                    }}
                    style={{ cursor: "pointer" }}
                    key={i}
                  >
                    <div className="package_main ">
                      <div className="text-center newGray">
                        <h4 className="font-60">£{e?.unit_amount / 100}</h4>

                        <p style={{ marginBottom: "0px" }} className="font-20">
                          {e?.product?.unit_label.split("-")[1] &&
                          e?.product?.unit_label.split("-")[1] == 1
                            ? `${
                                e?.product?.unit_label.split("-")[1]
                              } MONTH ACCESS`
                            : `${
                                e?.product?.unit_label.split("-")[1]
                              } MONTHS ACCESS`}
                        </p>
                        <p className="font-16" style={{ color: "#999ca5" }}>
                          one-time payment
                        </p>
                        {/* <p className="newTag font-16 bold">
                          SPECIAL LAUNCH PRICING
                        </p> */}
                      </div>
                      <div className="newPadding">
                        {e?.content.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div className="package_desc_main ">
                                <div className="tick_size">{item.icon}</div>
                                <div className="package_desc">
                                  <p>{item.p}</p>
                                  <ul>
                                    {item?.li?.map((li, i) => (
                                      <li key={i}>{li}</li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <div className="package_price_main">
                      <p>PURCHASE</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        )}
      </div>

      <Elements stripe={stripePromise}>
        <PaymentModal
          title={`Payment Methods`}
          show={packageId == null ? false : true}
          handleClose={handleClose}
          isLoading={buyPackageLoading}
          HandleBuyPackage={HandleBuyPackage}
          packageId={packageId}
          packageamountforApplePay={packageamountforApplePay}
          setPackageAmountForApplePay={setPackageAmountForApplePay}
          ourNewTotalAmount={ourNewTotalAmount}
          setOurNewTotalAmount={setOurNewTotalAmount}
          selectedAmountPackage={packageAmount}
        />
        {/* <PaymentMethodModal
          title={"Payment Methods"}
          show={packageId == null ? false : true}
          handleClose={handleClose}
          isLoading={buyPackageLoading}
          HandleBuyPackage={HandleBuyPackage}
          packageId={packageId}
          packageamountforApplePay={packageamountforApplePay}
          setPackageAmountForApplePay={setPackageAmountForApplePay}
          ourNewTotalAmount={ourNewTotalAmount}
          setOurNewTotalAmount={setOurNewTotalAmount}
        /> */}
      </Elements>

      <Footer />
    </div>
  );
};

export default PackageScreen;
