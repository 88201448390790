import { Offcanvas } from "bootstrap";
import React from "react";
import {
  Col,
  Container,
  Image,
  Row,
  Button,
  Navbar,
  Nav,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { logoNew } from "../constant/imagePath";

const Header = ({ LinkData }) => {
  const history = useHistory();
  return (
    <>
      <Navbar className="nav_main" expand="xl">
        <Container className="nav_cont">
          <div>
            <Navbar.Brand href="#" className="font-24 blue logo_main">
              PRE REG MASTER
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="j-end">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "1100px" }}
              navbarScroll
            >
              <div className=" d-flex mar_p_header">
                <Link className="a_noStyle" to="/">
                  <p>Home</p>
                </Link>

                {/* Explore */}
                {window.location.pathname !== "/" ? (
                  <Link
                    className="a_noStyle"
                    onClick={() => window.location.replace("/#sitemap")}
                  >
                    <p>Explore</p>
                  </Link>
                ) : (
                  <a className="a_noStyle" href="#sitemap">
                    <p>Explore</p>
                  </a>
                )}

                {/* Explore */}
                {window.location.pathname !== "/" ? (
                  <Link
                    className="a_noStyle"
                    onClick={() => window.location.replace("/#pricing")}
                  >
                    <p>Pricing</p>
                  </Link>
                ) : (
                  <a className="a_noStyle" href="#pricing">
                    <p>Pricing</p>
                  </a>
                )}

                <Link className="a_noStyle" to="/about">
                  <a className="a_noStyle">
                    <p>About Us</p>
                  </a>
                </Link>
                <a className="a_noStyle" target="_blank" href={LinkData?.url}>
                  <p>{LinkData?.name}</p>
                </a>
                <div className="header_btn_width">
                  <Link to="/signin">
                    <Button>LOGIN</Button>
                  </Link>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
