import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import {
  bowl,
  firstSection,
  second_section_left,
  tag,
  thirdIcon1,
  thirdIcon2,
  thirdIcon3,
  thirdIcon4,
  thirdSection_right,
  klarnaImg,
} from "../constant/imagePath";
import AccordionB from "../Components/AccordionB";
import SitemapDiscarded from "../Components/SitemapDiscarded";
import { apiUrl, imageUrl, URL } from "../Config/apiUrl";
import Helmet from "../Components/Helmet";
import { Get } from "../Axios/AxiosFunctions";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import HomepageVideoModal from "../Components/HomepageVideoModal";
import AboutCard from "../Components/AboutCard";
import OutsideAlerter from "../Components/OutsideAlerter";
import $ from "jquery";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch } from "react-redux";
import CarouselB from "../Components/CarouselB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const array = [
  {
    icon: <DoneIcon />,
    p: "BNF",
    li: "Exam focused",
  },
  {
    icon: <DoneIcon />,
    p: "Over The Counter",
    li: "Top 50 conditions",
  },
  {
    icon: <DoneIcon />,
    p: "Law",
    li: "Rules & regulations",
  },
  {
    icon: <DoneIcon />,
    p: "Calculations",
    li: "Worked examples",
  },
  // {
  //   icon: <DoneIcon />,
  //   p: "Mock Exam",
  //   li: "April 2022",
  // },
  {
    icon: <CloseIcon style={{ color: "red" }} />,
    p: "App NOT included",
    li: "500+ questions",
  },
];
const array6month = [
  {
    icon: <DoneIcon />,
    p: "BNF",
    li: "Exam focused",
  },
  {
    icon: <DoneIcon />,
    p: "Over The Counter",
    li: "Top 50 conditions",
  },
  {
    icon: <DoneIcon />,
    p: "Law",
    li: "Rules & regulations",
  },
  {
    icon: <DoneIcon />,
    p: "Calculations",
    li: "Worked examples",
  },
  // {
  //   icon: <DoneIcon />,
  //   p: "Mock Exam",
  //   li: "April 2022",
  // },
  // {
  //   icon: <DoneIcon />,
  //   p: "App included",
  //   li: "500+ questions",
  // },
];

const Home = () => {
  const ApiURL = URL(
    `cms/pages?page=home&sections=${true}&all=${false}&seo=true&services=true&testimonials=true&link=true`
  );
  const history = useHistory();

  const videoRef = useRef();
  const dispatch = useDispatch();
  const [blurLoader, setBlurLoader] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [dataList, setDataList] = useState();
  const [show, setShow] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [toggleChapter, setToggleChapter] = useState(false);
  const [durationVideo, setDurationVideo] = useState(null);
  const [chapterShow, setChapterShow] = useState([]);
  const [chapterName, setChapterName] = useState("");
  const [videoShow, setVideoShow] = useState([]);
  const [aboutUsData, setAboutUsData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // functions
  // ==== getPageData ===
  const getPageData = async () => {
    setBlurLoader(true);
    const responseData = await Get(ApiURL, undefined, true, dispatch);
    if (responseData !== undefined) {
      setDataList(responseData?.data?.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  const [selectedId, setSelectedId] = useState(null);

  const onClick1 = (id) => {
    if (selectedId == id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };
  console.log({ show });
  if (showVideo) {
    document.body.className += " show_overlay";
  } else {
    document.body.classList.remove("show_overlay");
  }
  useEffect(() => {
    // setShowVideo(true);
    console.log({ show });
  }, [show]);
  console.log(videoRef);
  var durationVdo = null;
  if (videoRef !== undefined) {
    console.log(videoRef?.current?.getCurrentTime());
    console.log(videoRef?.current?.getDuration());
    durationVdo = videoRef?.current?.getCurrentTime();
  }

  if (show == false) {
  }
  if (show) {
    // setDurationVideo(videoRef?.current?.getDuration());
  }
  console.log(showVideo);
  useEffect(() => {
    $("#Video-Player").focus(() => {
      $("#Video-Player").addClass("show-transform");
    });
  }, []);
  $("#Video-Player").focusin(() => {
    $("#Video-Player").addClass("show-transform");
  });

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const handleFirstStep = (item) => {
    console.log(item);
    setChapterShow(item.chapters);
    setChapterName(item);
    console.log(item);
  };
  console.log(chapterShow);

  const handleVideoShow = (item) => {
    return setVideoShow(item?.videos), setToggleChapter(true);
  };

  console.log("=============>>>>>>>>>>>>>>>>>>>", dataList);

  const testiArray = [
    {
      star: 5,
      message:
        "Great voiceovers and lovely presentations.I love that there are highlights and annotations as the tutor goes through the content. ",
      name: "Josh L, Trainee pharmacist",
    },
    {
      star: 5,
      message:
        "Have been using this for over one month and it has drastically improved my studying. It keeps me on top of the workload - I know how much I have left to cover and how long it will take.",
      name: "Abdullah K, Trainee pharmacist",
    },
    {
      star: 5,
      message:
        "This is fantastic because I can learn any topic at any time - and it’s better than all those “crash courses”!!!",
      name: "Louise M, Trainee pharmacist",
    },
    {
      star: 5,
      message:
        "The best features are #1 forum for every video to ask questions and #2 the resources linked to each video",
      name: "Omar, A, Trainee pharmacist",
    },
  ];

  return (
    <div className={blurLoader ? "pageBlur" : ""}>
      <Helmet data={dataList?.seo} />
      <div
        className="home_header_bg "
        style={{
          backgroundImage: `url(${imageUrl}${dataList?.pages?.cover_image})`,
        }}
      >
        <Header LinkData={dataList?.social[0]} />

        <Container
          fluid
          className="banner_width"
          style={{ overflow: showVideo ? "unset" : "hidden" }}
        >
          <Row>
            <Col md={6} className=" ">
              <div className="header_left_new">
                <div className="banner_first">
                  <CheckCircleOutlineIcon />
                  <p className="one_vw">
                    Developed by experienced cross-sector pharmacists
                  </p>
                </div>
                <h3 className="mb-4 homePageHeading text-left three_vw">
                  {dataList?.pages?.heading}
                  {/* Master Pre Reg In <br /> 150+ Videos */}
                </h3>

                <h5 className="mb-4 text-left font-18">
                  {dataList?.pages?.detail_description}
                  {/* Pharmacy training like you’ve never seen before. */}
                </h5>
                <div className="pt-3 mb-4  d-flex a-center">
                  <Link
                    style={{ marginRight: "20px" }}
                    to="/signup"
                    className="signup_btn"
                  >
                    <Button className="header_btn1 ">SIGN UP</Button>
                  </Link>{" "}
                  <a href="#sitemap">
                    <p
                      style={{
                        marginBottom: "0px",
                        height: "100%",
                        marginLeft: "10px",
                      }}
                      className="blue d-flex a-center explore_btn"
                    >
                      EXPLORE THE COURSE
                    </p>
                  </a>
                </div>
              </div>
            </Col>
            <Col
              md={6}
              className={showVideo ? "modal_show pad-0" : "modal_close pad-0"}
            >
              <div className="home_video_main">
                <div
                  id="Video-Player"
                  className={showVideo ? "videoBig" : "videoBBB"}
                >
                  <OutsideAlerter
                    onSubmit={() => {
                      setShowVideo(false);
                    }}
                  >
                    <ReactPlayer
                      url={dataList?.pages?.display_video}
                      // url={"https://www.youtube.com/watch?v=H_bB0sAqLNg"}
                      // onClick={handleShow}
                      // onPlay={handleShow}
                      onReady={true}
                      onStart={() => setShowVideo(true)}
                      onPlay={() => setShowVideo(true)}
                      onPause={() => setShowVideo(false)}
                      ref={videoRef}
                      playing={showVideo}
                      controls={true}
                      volume={0}
                    />
                  </OutsideAlerter>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="text-center first_section_main">
              {/* <Image
              src={`${imageUrl}${dataList?.pages?.display_image}`}
              alt=""
              className="img-fluid"
            /> */}

              {/* <OutsideAlerter> */}

              {/* </OutsideAlerter> */}

              {/* <Image src={firstSection} /> */}
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid className="testi_top_mar">
        <Row>
          <Col className="text-center">
            <p className="bold mb-5">What others say...</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <CarouselB item={dataList?.testimonials} />
          </Col>
        </Row>
      </Container>
      {/* sad*/}{" "}
      <Container fluid className="second_sec_top">
        <Row>
          <Col md={6} className="text-end">
            <Image
              src={`${imageUrl}${dataList?.pages?.sec1Image}`}
              alt=""
              className="img-fluid"
            />
          </Col>
          <Col md={6} className="padding_left_second_section">
            <h4 className="font-36  ">
              {dataList?.pages?.sec1Heading}
              {/* Isn’t the Pre Reg Master <br /> app enough? */}
            </h4>
            <p
              className="mb-5
            "
              style={{ color: "#000" }}
            >
              We’ve done the hard work for you.
            </p>

            <p className="p-width">
              {dataList?.services !== undefined &&
                dataList.services.map((item, i) => (
                  <div className="serviceContainer">
                    <div
                      // style={{ background: hexToRGB(item?.color, 0.2) }}
                      className="imageContainer"
                    >
                      <Image src={`${imageUrl}${item?.icon}`} />
                    </div>
                    <div>
                      <p
                        style={{
                          color: item?.color,
                          fontWeight: 600,
                          marginBottom: "5px",
                        }}
                      >
                        {item?.title}
                      </p>
                      <p style={{ marginBottom: "30px" }}>
                        {item?.description}
                      </p>
                    </div>
                  </div>
                ))}
              {/* {dataList?.pages?.sec1Description} */}
              {/* The simple answer is no! When we created Pre Reg Master, our
              objective was to ensure that all trainee pharmacists had access to
              relevant questions that actually came up in the exam. */}
            </p>
            {/* <p className="p-width">
              Trainees then began to request training that would help them
              answer questions in the first place. The BNF is over 1600 pages
              long, and not all of it is relevant for the exam... so here we
              are!
            </p> */}
          </Col>
        </Row>
      </Container>
      <div className="third_sec_bg">
        <Container className="third_sec_new">
          <Row>
            <Col md={6}>
              <h3 className="font-40 cus_width_third blue">
                Questions? Ask us anything, we will reply within hours.
              </h3>
            </Col>
            <Col className="l_border">
              <div className="third-col-right">
                <p>
                  Not only have we expertly created 150 videos, we will also
                  follow up with you on any queries you may have!
                  <span style={{ marginLeft: "7px" }} className="blue">
                    Every single video has a dedicated forum.
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Container>
        <Row>
          <Col>
            <SitemapDiscarded />
          </Col>
        </Row>
      </Container> */}
      {/* <Container id="sitemap" className="mt-5">
        <Row>
          <Col>
            {dataList?.sections !== undefined &&
              dataList?.sections.map((item, i) => {
                return (
                  <div className="twenty_percent">
                    <AccordionB
                      item={item}
                      key={i}
                      showMore={showMore}
                      onChange={onClick1}
                      selected={selectedId}
                    />
                  </div>
                );
              })}
            <div
              onClick={() => {
                setShowMore(!showMore);
              }}
              className="text-center"
            >
              <h2>{showMore ? "Show Less" : " Show More"} </h2>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container id="sitemap" className="mt-5">
        <Row>
          <Col>
            <h6 className="font-40 text-center">Explore the Dashboard</h6>
            <p className="font-16 text-center mb-5">
              Click around to discover the 150+ videos...
            </p>
          </Col>
        </Row>
        <Row className="cus_width_sitemap">
          {dataList?.sections !== undefined && chapterShow.length == 0 ? (
            dataList?.sections.map((item, i) => {
              console.log(item);
              return (
                <Col md={6} className="sections_name">
                  <div onClick={() => handleFirstStep(item)}>
                    {item.sectionName}
                  </div>
                </Col>
              );
            })
          ) : (
            <></>
          )}

          {/* <div
            onClick={() => {
              setShowMore(!showMore);
            }}
            className="text-center"
          >
            <h2>{showMore ? "Show Less" : " Show More"} </h2>
          </div> */}
        </Row>
        <Row className="chap_vid_row">
          {chapterShow.length !== 0 && toggleChapter == false ? (
            <h6 className="center_text" onClick={() => setChapterShow([])}>
              {" "}
              <ChevronLeftIcon /> Back to overview
              {/* <ChevronLeftIcon /> Back To {chapterName.sectionName} */}
            </h6>
          ) : (
            <></>
          )}
          <div className="chapters_show">
            {chapterShow.length !== 0 && toggleChapter == false ? (
              chapterShow.map((item, i) => {
                return (
                  <>
                    <h6 onClick={() => handleVideoShow(item)}>
                      {item?.chapterName}
                    </h6>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Row>
        <Row className="chap_vid_row">
          {videoShow.length !== 0 && toggleChapter == true && (
            <h6 className="center_text" onClick={() => setToggleChapter(false)}>
              {" "}
              <ChevronLeftIcon /> {`Back to ${chapterName?.sectionName}`}
              {/* <ChevronLeftIcon /> Back to overview */}
            </h6>
          )}
          <div className="chapters_show videos_btn">
            {videoShow.length !== 0 && toggleChapter == true ? (
              videoShow.map((item, i) => {
                return (
                  <>
                    <h6>{item?.name}</h6>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Row>
      </Container>
      {/* New Last Section */}
      <div className="ready__div">
        <Container className="ready__container">
          <h2>Ready to learn?</h2>
          <p className="ready__p1">
            Take Pre Reg Master Dashboard for a test drive when you start your
            free trial.*
          </p>
          <span onClick={() => history.push("/signup")}>
            Access Free Videos Now
          </span>
          <p className="ready__p2">
            *The dashboard is only supported on desktop devices.
          </p>
        </Container>
      </div>
      {/* About Cards */}
      {aboutUsData?.length > 0 && (
        <section className="about-container" id="about">
          <h6 className="font-40 text-center">About Us</h6>
          <Container>
            <Row>
              {aboutUsData?.map((item, i) => {
                return (
                  <Col md={4} key={item?._id}>
                    <AboutCard data={item} />
                  </Col>
                );
              })}
              {/* <Col md={4}>
                <AboutCard />
              </Col>
              <Col md={4}>
                <AboutCard />
              </Col>
              <Col md={4}>
                <AboutCard />
              </Col> */}
            </Row>
          </Container>
        </section>
      )}
      <Footer />
      <HomepageVideoModal
        show={show}
        handleClose={handleClose}
        durationVdo={durationVdo}
      />
    </div>
  );
};

export default Home;

// <div className="last_sec_bg mb-5 pb-5 " id="pricing">
// <Container className="last_section">
//   <Row>
//     <Col className="p_colors text-center mb-5 mt-5">
//       <h4>Sign Up for a Free Trial</h4>
//       <p>
//         All of our packages include access to the support dashboard.
//         Feel free to ask us anything there.
//       </p>
//       <p>The dashboard is only supported on desktop devices.</p>
//       {/* <img className="img-fluid" src={klarnaImg} /> */}
//     </Col>
//   </Row>
//   <Row className={"sp_b_mid"}>
//     {/* <Col lg={4} md={12} sm={12} className="pricing_table">
//       <div className="package_main ">
//         <div className="text-center newGray">

//           <p style={{ marginBottom: "0px" }} className="font-20 bold">
//             6MONTHS ACCESS
//           </p>
//           <p className="font-16 " style={{ color: "#999ca5" }}>
//             one-time payment
//           </p>
//           <p className="newTag font-16 bold">SPECIAL LAUNCH PRICING</p>
//         </div>
//         <div className="newPadding">
//           {array6month.map((item, i) => {
//             return (
//               <>
//                 <div className="package_desc_main ">
//                   <div className="tick_size">{item.icon}</div>
//                   <div className="package_desc">
//                     <p style={{ fontWeight: "600", color: "#616368" }}>
//                       {item.p}
//                     </p>
//                     <ul>
//                       <li style={{ color: "#616368" }}>{item.li}</li>
//                     </ul>
//                   </div>
//                 </div>
//               </>
//             );
//           })}
//         </div>
//       </div>
//       <Link to="/signup" className="package_price_main">
//         <div>
//           <p>SIGN UP</p>
//         </div>
//       </Link>
//     </Col> */}
//     <Col lg={4} md={12} sm={12} className="pricing_table">
//       {/* <Image src={tag} className="price_tag" /> */}
//       <div className="package_main ">
//         <div className="text-center newGray">
//           {/* <h4 className="font-60">£249</h4> */}

//           <p style={{ marginBottom: "0px" }} className="font-20 bold">
//             6 OR 12 MONTHS ACCESS
//           </p>
//           {/* <p className="font-16 " style={{ color: "#999ca5" }}>
//             one-time payment
//           </p> */}
//           {/* <p className="newTag font-16 bold">SPECIAL LAUNCH PRICING</p> */}
//         </div>
//         <div className="newPadding">
//           {array6month.map((item, i) => {
//             return (
//               <>
//                 <div className="package_desc_main ">
//                   <div className="tick_size">{item.icon}</div>
//                   <div className="package_desc">
//                     <p style={{ fontWeight: "600", color: "#616368" }}>
//                       {item.p}
//                     </p>
//                     <ul>
//                       <li style={{ color: "#616368" }}>{item.li}</li>
//                     </ul>
//                   </div>
//                 </div>
//               </>
//             );
//           })}
//         </div>
//       </div>
//       <Link to="/signup" className="package_price_main">
//         <div>
//           <p>SIGN UP</p>
//         </div>
//       </Link>
//     </Col>
//   </Row>
// </Container>
// </div>
