import React, { useState } from "react";
// import logo from '../assets/Logo.png';
import { Col, Container, Row } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { logoNew } from "../constant/imagePath";
import { URL } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const Signup = (params) => {
  const [response, setResponse] = useState();
  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
  });
  const handleChange = (e) => {
    console.log("change handler");
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  const signupResponse = URL(`users/signup`);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...inputs,
    };
    if (data.name == "" || data.email == "") {
      toast.error("Enter All Fields ");
      return;
    }
    console.log(data);
    setLoader(true);
    const response = await Post(signupResponse, data);
    setLoader(false);

    if (response !== undefined) {
      setToggle(true);
    }
    console.log(response);
  };

  return (
    <>
      <div className="pre-reg text-center">
        <Container className="container">
          <Row className="row">
            <Col md={12} className="logo">
              <img src={logoNew} alt="logo" style={{ width: "80px" }} />
            </Col>
            <Col md={12} className="web-name">
              <h2>PRE REG MASTER</h2>
            </Col>
            <Col md={12} className="signup-container">
              <div className="pad-singup">
                <Col md={12}>
                  <h2>Sign Up</h2>
                </Col>
                <form onSubmit={handleSubmit}>
                  <Col md={12} className="input input_styling">
                    <input
                      className="mb-3"
                      type="text"
                      name="name"
                      id="username"
                      placeholder="Full Name"
                      value={inputs.name}
                      onChange={handleChange}
                      onKeyPress={(e) => console.log(e)}
                      onKeyUp={(e) => console.log(e)}
                    />
                    <input
                      className="mb-3"
                      type="text"
                      name="email"
                      id="username"
                      placeholder="Email Address"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                    {/* <div className="input_eye">
                <input
                className="mb-3"

                  type={ toggle ? 'text' :"password"}
                  name='password'
                  id='username'
                  placeholder='Create Password'
                  value={inputs.password}
                  onChange={handleChange}


                />

            <VisibilityIcon onClick= {()=> setToggle(!toggle)}/>
                </div> */}
                  </Col>

                  {/* <Col md={12} className='pass-strength'>
                <PasswordStrengthBar password={inputs.password} />
              </Col> */}
                  <Col md={12} className="para">
                    <p className="para-text">
                      By signing up, you confirm that you've read
                      <br /> and accepted our
                      <Link to="/privacy-policy">
                        <span style={{ color: "#3090ef" }}>
                          {" "}
                          Privacy Policy
                        </span>
                      </Link>
                      <span> and</span>
                      <Link to="/terms-and-condition">
                        <span style={{ color: "#3090ef" }}> T&Cs</span>
                      </Link>
                    </p>
                  </Col>
                  <Col md={12} className="signup-btn">
                    <button
                      type="submit"
                      disabled={toggle || loader ? true : false}
                      className={toggle && "btn-success_b"}
                    >
                      {toggle
                        ? "Please check your email including spam folder"
                        : loader
                        ? " loading.."
                        : "Register"}
                    </button>
                  </Col>
                </form>
              </div>
              <Link to="/signin">
                <p className=" signup_last_text">
                  Already have a Pre Reg Master account? Log in
                </p>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Signup;
