import React, { useEffect, useState } from "react";
import { Col, Container, Row ,Button} from "react-bootstrap";

import {
    logoNew,
  } from "../constant/imagePath";


const ScreenShotMsg = ({setShowSSAlert}) => {
    
  return (
    <>
      <div className="pre-reg">
        <Container className="container">
          <Row className="row style-h">
            <Col md={12} className="logo text-center">
              <img src={logoNew} alt="logo" style={{ width: "80px" }} />
            </Col>
            <Col md={12} className="web-name text-center mb-4">
              <h2>PRE REG MASTER</h2>
            </Col>
            <Col md={12} className="web-name text-center mb-4">
              <h5 >You are not allowed to take screenshots. Your action has been logged.</h5>
            </Col>
            
            <Col md={12} className="web-name text-center mb-4">
            <Button
            className="whito"
            onClick={() => {
              setShowSSAlert(false);
            }}
          >
            Return To Pre Reg Master
          </Button>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
};

export default ScreenShotMsg;
