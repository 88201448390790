import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";
import { URL, validateEmail } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Patch, Post } from "../Axios/AxiosFunctions";
import { saveLoginUserData, updateUserData } from "../store/Actions/authAction";

const ForgotPasswordModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authReducer?.access_token);
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  //   handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = {
      email,
    };

    if (!validateEmail(email)) {
      return toast.warn("Please enter a valid email address.");
    }

    const url = URL(`users/forgotPassword`);
    setLoading(true);
    const response = await Post(url, params, header);
    setLoading(false);
    if (response !== undefined) {
      setShow(false);
      toast.success("Password reset link sent to your email.");
    }
  };

  return (
    <Modal
      centered
      show={show}
      className="modal-Contaier payment-cards-modal"
      onHide={() => {
        setShow(false);
      }}
      backdrop={false}
    >
      <div className="row borderBottomColor pr-0 pl-0  ">
        <div className="col-md-12 d-flex flex-column justify-content-center text-center">
          <div className="close-icon-container" onClick={() => setShow(false)}>
            <CloseIcon className="c-p" />
          </div>

          {/* Form */}
          <Row md={12} className="signin-container">
            <Col md={12}>
              <h2>Forgot Password?</h2>
              <p>Enter Your email. We will send you a password reset link.</p>
            </Col>
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "30px",
              }}
            >
              <Col md={12} className="input input_styling">
                <div>
                  <input
                    className="mb-3"
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={12}>
                <button
                  type="submit"
                  className="btn btn-blue-x"
                  disabled={loading}
                >
                  {loading ? "Please Wait..." : "Send Email"}
                </button>
              </Col>
            </form>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
