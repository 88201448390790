import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { imageUrl } from "../Config/apiUrl";
import parse from "html-react-parser";

const img =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";

const AboutCard = ({ data }) => {
  return (
    <div className="about-card">
      <div className="about-img-container">
        {/* <img src={`${imageUrl}${data?.image}`} className="about-img" /> */}
        <img src={`${imageUrl}${data?.image}`} className="about-img" />
      </div>
      <div className="about-titleAndDesignation-container">
        <h2 className="about-title">{data?.title}</h2>
        {/* <p className="about-designation">{data?.designation}</p> */}
      </div>
      <div className="about-description-container">
        <FaQuoteLeft className="quote-icon" />
        <span className="about-description">
          {data?.text && parse(data?.text)}
        </span>
      </div>
      {/* <div className="about-learnMore">
        <button>Learn More</button>
      </div> */}
    </div>
  );
};

export default AboutCard;
