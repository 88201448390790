// import logo from "./logo.svg";
// import { Button, Col, Container, Image, Row } from "react-bootstrap";
// // import "./Assets/style/style.css";
// import "../src/assets/Styles/style.css";
// import "../src/assets/Styles/HomePage.css";
// import Home from "./Screens/Home";
// import Header from "./Components/Header";
// import Footer from "./Components/Footer";
// import SidebarCus from "./Components/SidebarCus";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Routes,
// } from "react-router-dom";
// import Signin from "./Screens/Signin";
// import Signup from "./Screens/Signup";
// import { useSelector } from "react-redux";
// import Test from "./Screens/Test";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Component } from "react";
// import { BrowserView, MobileView } from "react-device-detect";
// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { loginFlag: -1 };
//   }

//   componentDidMount() {
//     const Ctrl = document.getElementById("Ctrl");
//     console.log({ Ctrl });
//     // this.setState({
//     //   loginFlag: Ctrl.initUxtApi(),
//     // });
//   }

//   render() {
//     // return <div>{this.state.loginFlag}</div>;
//     return (
//       <>
//         <BrowserView>
//           <h1>This is rendered only in browser</h1>
//         </BrowserView>
//         <MobileView>
//           <h1>This is rendered only on mobile</h1>
//         </MobileView>
//       </>
//     );
//   }
// }

// export default App;

import logo from "./logo.svg";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
// import "./Assets/style/style.css";
import "../src/assets/Styles/style.css";
import "../src/assets/Styles/HomePage.css";
import Home from "./Screens/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import SidebarCus from "./Components/SidebarCus";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Redirect,
} from "react-router-dom";
import Signin from "./Screens/Signin";
import Signup from "./Screens/Signup";
import { useDispatch, useSelector } from "react-redux";
import Test from "./Screens/Test";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL } from "./Config/apiUrl";
import { Get } from "./Axios/AxiosFunctions";
import {
  saveHomeSectionData,
  saveSectionData,
} from "./store/Actions/authAction";
import { useEffect, useState } from "react";
import Support from "./Screens/Support";
import TermsAndCondition from "./Screens/TermsAndCondition";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import CookieConsent from "react-cookie-consent";
import PackageScreen from "./Screens/PackageScreen";
import Thankyou from "./Screens/Thankyou";
import ComingSoonPage from "./Screens/ComingSoonPage";
import { useHistory } from "react-router";
import ProtectedRoute from "./Components/ProtectedRoute";

import $ from "jquery";
import ScreenShotMsg from "./Screens/ScreenShotMsg";

import MobileRestrictedComponent from "./Components/MobileRestrictedComponent";
import AboutUs from "./Screens/AboutUs";
import Videos from "./Screens/Videos";
import ThreeDs from "./Screens/3Ds";

function App() {
  // new
  const [selected, setSelected] = useState(null);
  console.log(selected, "selectedselectedselected");
  let arr = [91, 16, 51, 52, 80];
  const [allKeys, setAllKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const [showSSAlert, setShowSSAlert] = useState(false);

  useEffect(() => {
    if (allKeys.length !== 0) {
      if (allKeys.includes(91) && allKeys.includes(16)) {
        setShowSSAlert(true);
        setAllKeys([]);
      }
      if (allKeys.includes(91) && allKeys.includes(80)) {
        setShowSSAlert(true);
        setAllKeys([]);
      }
      if (!arr.includes(allKeys[allKeys.length - 1])) {
        setAllKeys([]);
      }
    }
  }, [allKeys]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (
        e.keyCode == 91 ||
        e.keyCode == 16 ||
        e.keyCode == 51 ||
        e.keyCode == 52 ||
        e.keyCode == 80
      ) {
        setAllKeys((p) => [...p, e.keyCode]);
      }
      // if (e.keyCode !== 8) {
      //   setAllKeys((p) => [...p, e.keyCode]);
      // }
      // if(e.key !== "Delete"){
      //   setAllKeys((p) => [...p, e.keyCode]);
      // }
    });

    document.addEventListener("keypress", (e) => {
      if (e.ctrlKey && e.key == "p") {
        document.write("This section is not allowed to print or export to PDF");
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
    document.onkeydown = function (e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
      if (e.keyCode == 91 && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
    };
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.authReducer);

  const ApiURL = URL(`cms/public/section`);
  
  const getSectionData = async () => {
    const responseData = await Get(ApiURL, undefined, true, dispatch);
    if (responseData !== undefined) {
      dispatch(saveHomeSectionData(responseData?.data?.data));
    }
  };

  useEffect(() => {
    getSectionData();
  }, []);

  console.log(
    "Condition: ",
    userData?.isLogin && userData?.user?.isSubscriptionOn == false
  );

  return (
    // <Router>
    //   <MobileRestrictedComponent />
    // </Router>

    <>
      {showSSAlert ? (
        <div>
          <ScreenShotMsg setShowSSAlert={setShowSSAlert} />
        </div>
      ) : (
        <Router>
          <ToastContainer />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            We use cookies to ensure you get the best experience on our website.{" "}
            <span style={{ fontSize: "10px" }}>
              {/* This bit of text is smaller :O */}
            </span>
          </CookieConsent>

          {userData?.isLogin && userData?.user?.isSubscriptionOn == true ? (
            <>
              <Redirect to="/dashboard" />
              <SidebarCus selected={selected} setSelected={setSelected} />
            </>
          ) : userData?.isLogin && userData?.user?.isSubscriptionOn == false ? (
            <>
              <Switch>
                <Route exact path="/dashboard">
                  <SidebarCus selected={selected} setSelected={setSelected} />
                </Route>
                <ProtectedRoute exact path="/Videos">
                  <SidebarCus selected={selected} setSelected={setSelected}>
                    <Videos
                      data={selected}
                      // data={selected == null ? [] : selected}
                      // data={selected == null ? [{ chapters: [] }] : selected}
                      handleSelectSection={() => {}}
                    />
                  </SidebarCus>
                </ProtectedRoute>
                <Route exact path="/packages">
                  <PackageScreen />
                </Route>
                <Route exact path="/thankyou">
                  <Thankyou />
                </Route>
                <Route exact path="/3ds">
                  <ThreeDs />
                </Route>
                <Route path="*">
                  <Redirect to="/packages" />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              {/* <Route exact path="/demohome">
                <ComingSoonPage />
              </Route> */}
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/about">
                <AboutUs />
              </Route>
              <Route exact path="/signin">
                <Signin />
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>
              <Route exact path="/terms-and-condition">
                <TermsAndCondition />
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/packages">
                <PackageScreen />
              </Route>
              <Route exact path="/thankyou">
                <Thankyou />
              </Route>
            </Switch>
          )}
          {/* <Switch>
            <Route path="*">
              <Thankyou />
            </Route>
          </Switch> */}
        </Router>
      )}
    </>
  );
}

export default App;
