import { useSelector } from "react-redux";
import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ path, children }) {
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);

  return (
    <Route path={path}>
      {isAuthenticated == true ? children : <Redirect to="/" />}
    </Route>
  );
}

export default ProtectedRoute;
