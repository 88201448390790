import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import SearchInput from "./SearchInput";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Image } from "react-bootstrap";
import { user } from "../constant/imagePath";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { URL } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { signOutRequest } from "../store/Actions/authAction";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ChangePasswordModal from "./ChangePasswordModal";
const HeaderSigned = ({
  hide = false,
  setSearchText,
  searchText,
  responseArray,
  handleSelect,
  videoHide = true,
  newLeftShow = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // console.log("signed ===>", newLeftShow);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = useSelector((state) => state?.authReducer?.access_token);
  const userData = useSelector((state) => state?.authReducer?.user);

  const postCraftmenData = URL(`users/logout`);
  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const handleLogout = async () => {
    // const data = {
    //   fcmToken: 786,
    // };
    // const response = await Post(postCraftmenData, data, header);
    // console.log(response?.status);
    // if (response?.status == 200) {
    dispatch(signOutRequest());
    history.push("/");
    // }
    // handleClose();
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    var values = name.split(" ");
    var fisrtNameAlphabet = values[0] ? values[0].charAt(0) : "";
    var lastNameAlphabet = values[1] ? values[1].charAt(0) : "";
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${fisrtNameAlphabet.toUpperCase()}${lastNameAlphabet.toUpperCase()}`,
    };
  }
  const userName = userData?.name;
  return (
    <>
      <div className="signed_header">
        <div className={hide ? "search_main hide" : "search_main"}>
          <div className={`sidebar_search`}>
            <input
              type="text"
              placeholder={"Search chapters..."}
              style={{ backgroundColor: "#fafafb" }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className={`${
                responseArray !== undefined &&
                responseArray.length > 0 &&
                "searchInputBorder"
              }`}
              value={searchText}
            />
            <SearchIcon />
          </div>

          {responseArray !== undefined && responseArray.length > 0 && (
            <div className="video_search_data_container">
              <div className="video_search_data">
                {responseArray.map((e, i) => (
                  <p
                    onClick={() => {
                      handleSelect(e);
                    }}
                  >
                    {e.chapterName}
                  </p>
                ))}
              </div>
            </div>
          )}

          {/* <SearchInput /> */}
        </div>
        <div className="d-flex center_div_header">
          {videoHide && newLeftShow && (
            <NavLink to="/videos">
              <p className="font-weight one-p">Videos</p>
            </NavLink>
          )}
          <NavLink to="/Support">
            <p className="font-weight two-p">Support</p>
          </NavLink>
        </div>

        <div className="menu_main d-flex a-center" onClick={handleClick}>
          {/* <Image className="user_img" src={user} /> */}
          <Stack direction="row" spacing={2}>
            <Avatar {...stringAvatar(userName)} />
          </Stack>
          <Button
            className="font-13"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {userData?.name}
          </Button>
          <ArrowDropDownIcon />
        </div>
      </div>
      <Menu
        className="menu_cus"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}

        <MenuItem
          className="menu-item-x"
          onClick={() => {
            setIsChangePasswordModalOpen(true);
            handleClose();
          }}
        >
          Change Password
        </MenuItem>
        <MenuItem className="menu-item-x" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>

      <ChangePasswordModal
        show={isChangePasswordModalOpen}
        setShow={setIsChangePasswordModalOpen}
      />
    </>
  );
};

export default HeaderSigned;
