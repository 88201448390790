import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Get, Post } from "../Axios/AxiosFunctions";
import { apiUrl, URL } from "../Config/apiUrl";
import { signOutRequest, updateUserData } from "../store/Actions/authAction";
import { useHistory } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import logo from "../assets/Images/logo.png";
import { toast } from "react-toastify";

const ThreeDs = () => {
  const token = useSelector((state) => state?.authReducer?.access_token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [responseData, setResponseData] = useState(null);

  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const [isApiCall, setISApiCall] = useState(true);

  const getUserData = async () => {
    try {
      const _xpxpx = JSON.parse(localStorage.getItem("_xpxpx"));
      const _xp__pc__id = localStorage.getItem("_xp__pc__id")
      if (!_xpxpx) {
        return history.replace("/");
      }

      const mainUrl = URL(
        `users/confirm3d-subscription${window.location.search}&priceId=${_xp__pc__id}`
      );
      // let params = new URLSearchParams(mainUrl);

      // let redirect_status = params.get("redirect_status");
      // // if we have an error
      // if (redirect_status == null || redirect_status == "failed") {
      //   toast.error("Task failed. Please try again later.");
      //   history.push("/packages");
      //   // setISApiCall(false);
      //   return;
      // }

      const response = await Post(mainUrl, _xpxpx, header);
      if (response !== undefined) {
        if (response?.data?.error == true) {
          toast.error("Payment failed. Please try again later.");
          return history.push("/packages");
        }

        setResponseData(response?.data?.data);
        localStorage.removeItem("_xpxpx");
        localStorage.removeItem("_xp__pc__id");
        setTimeout(() => {
          updateUser(response?.data?.data);
        }, 2000);
        setISApiCall(false);
      } else {
        setISApiCall(false);
        dispatch(signOutRequest());
        history.push("/");
      }
    } catch (error) {
      console.log("ooooooooooooopppppppppppppp ", error);
    }
  };

  const updateUser = (response) => {
    dispatch(updateUserData(response));
    history.replace("/dashboard");
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Container className="nav_cont thankyou--master">
      <div className="thankyou-container last_sec-bg">
        <Navbar.Brand href="#" className="font-24 blue logo_main">
          PRE REG MASTER
        </Navbar.Brand>
        <Navbar.Brand href="#" className="font-24 blue logo_main">
          <img src={logo} />
        </Navbar.Brand>
      </div>
      {!isApiCall && (
        <div className="thankyou-inner">
          <h1>Congratulations</h1>
          <p>
            You have successfully unlocked a complete programme focusing on the
            most examinable content!
          </p>
          <p className="thankyou-wait">Please hold on a moment!</p>
        </div>
      )}

      {isApiCall && (
        <div className="thankyou-inner">
          <h1>Please Hold on a moment</h1>
          <p className="thankyou-wait">You will be redirected shortly!</p>
        </div>
      )}
    </Container>
  );
};

export default ThreeDs;
