import React, { useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { FormControlLabel, IconButton, Radio } from "@material-ui/core";
import { masterCardLogo, noPayment, visaCardLogo } from "../constant/imagePath";

const AvaiableCreditCards = ({
  key,
  availableCard,
  paymentMethod,
  setPaymentMethod,
  handleDetachCard,
  setDetachCard,
}) => {
  return (
    <>
      <div className="pt-2 pb-2 pr-2 ml-3 mr-3 crditCardItem">
        <FormControlLabel
          value={availableCard.id.toString()}
          labelPlacement={"start"}
          label={
            <div className="d-flex align-items-center justify-content-between pb-1 pt-1 ">
              <IconButton
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  outline: "none",
                }}
              >
                <CancelIcon
                  className="close-icon-payment-modal"
                  onClick={() => {
                    handleDetachCard(availableCard.id);
                  }}
                />
              </IconButton>
              <img
                className="img-fluid pr-1"
                style={{ width: "50px", height: "auto" }}
                src={availableCard.cardImage}
              />
              <div className="d-flex align-items-start flex-column ml-2">
                <p className="debit-credit-card-label">
                  {availableCard.cardName}
                </p>
                <p className="expiry-date-p">{`****${availableCard.last4digits}`}</p>
              </div>
              <div className="ml-5">
                <p className="expiry-p">Expiration date:</p>
                <p className="expiry-date-p">
                  {availableCard.expirationMonth}/{availableCard.expirationYear}
                </p>
              </div>
            </div>
          }
          control={<Radio disableRipple={true} />}
          onClick={(e) => {
            if (e.target.value === paymentMethod) {
              setPaymentMethod("");
            } else {
              setPaymentMethod(e.target.value);
            }
          }}
        />
      </div>
    </>
  );
};

export default AvaiableCreditCards;
