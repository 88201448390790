import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  SET_SECTION,
  UPDATE_USER,
  SET_HOME_SECTION,
} from "./actionType";

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: response };
}
export function updateUserData(response) {
  return { type: UPDATE_USER, payload: response };
}
export function saveSectionData(response) {
  return { type: SET_SECTION, payload: response };
}

export function saveHomeSectionData(response) {
  return { type: SET_HOME_SECTION, payload: response };
}

export function signOutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}
