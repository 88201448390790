import { useDispatch, useSelector } from "react-redux";
import { URL } from "../Config/apiUrl";
import React, { useState, useEffect } from "react";
import { Get } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { SpinnerRoundOutlined } from "spinners-react";
import SubFooter from "../Components/SubFooter";
import Header from "../Components/Header";
import parse from "html-react-parser";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { logoNew } from "../constant/imagePath";
import { Link } from "react-router-dom";

const TermsAndCondition = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const [blurLoader, setBlurLoader] = useState(true);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [menuList, setMenuList] = useState([]);
  const [selection, setSelection] = useState(null);
  const dispatch = useDispatch();

  // functions
  const getTermcondition = async () => {
    setBlurLoader(true);
    const TermUrl = URL(`cms/term-and-condition`);
    const responseData = await Get(TermUrl, accessToken, true, dispatch);
    if (responseData !== undefined) {
      setMenuList(responseData?.data?.data);
      setSelection(responseData?.data?.data[0]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getTermcondition();
  }, []);

  return (
    <div className={blurLoader ? "pageBlur" : "term_and_pp_Container"}>
      <section className="navbar_section_PP">
        <Row className="style-h">
          <Col md={12} className="logo text-center">
            <Link to="/">
              <img src={logoNew} alt="logo" style={{ width: "80px" }} />
            </Link>
          </Col>
          <Col md={12} className="web-name text-center mt-4">
            <h2 style={{ color: "white" }}>PRE REG MASTER</h2>
          </Col>
        </Row>
      </section>

      <Container fluid className="term_and_pp_header_bg whito">
        <Row>
          <Col md={12} className="text-center ">
            <h3 className="mb-5 term_and_pp_heading">Terms and Conditions</h3>

            {/* <h5 className="mb-5">
              Pharmacy training like you’ve never seen before.
            </h5> */}
          </Col>
        </Row>
      </Container>

      <section class="second_section">
        <div class="container custom_container">
          <div class="row">
            {blurLoader ? (
              <div className="col-md-12 darken-Loading">
                <SpinnerRoundOutlined
                  enabled={blurLoader}
                  thickness={200}
                  color="#368ef5"
                />{" "}
              </div>
            ) : (
              <>
                <div class="col-md-3">
                  <div class="inner-col">
                    <nav>
                      <ul className="sidebar-inner-Container">
                        {menuList.map((item, index) => {
                          return (
                            <>
                              <li key={index}>
                                <a
                                  className="active"
                                  onClick={() => {
                                    setSelection(item);
                                  }}
                                  style={
                                    item?._id == selection?._id
                                      ? {
                                          color: "#368EF5",
                                        }
                                      : {}
                                  }
                                >
                                  {item.title}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="col-md-9 content">
                  <div class="inner-para-col">
                    {selection?.content !== undefined &&
                      parse(selection?.content)}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <SubFooter />
      </section>
    </div>
  );
};

export default TermsAndCondition;
