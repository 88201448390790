import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { imageUrl, URL } from "../Config/apiUrl";
import "../assets/Styles/About.css";
import AboutCard from "../Components/AboutCard";
import Footer from "../Components/Footer";
import { Get } from "../Axios/AxiosFunctions";
import { useDispatch } from "react-redux";

const AboutUs = () => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState();
  const [blurLoader, setBlurLoader] = useState(false);

  const ApiURL = URL(
    `cms/pages?page=about_us&all=${false}&seo=true&link=true&aboutus=true`
  );

  const getPageData = async () => {
    setBlurLoader(true);
    const responseData = await Get(ApiURL, undefined, true, dispatch);
    console.log("responseData", responseData?.data?.data.social[0]);

    if (responseData !== undefined) {
      setDataList(responseData?.data?.data);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  console.log(dataList?.social[0]);
  return (
    <>
      <div className={blurLoader ? "pageBlur" : ""}>
        <div
          className="about_header_bg "
          style={{
            backgroundImage: `url(${imageUrl}${dataList?.pages?.cover_image})`,
          }}
        >
          <Header LinkData={dataList?.social[0]} />
          <Container>
            <Row>
              {dataList?.aboutus?.map((item, index) => {
                return (
                  <Col key={index} lg={4} md={6} sm={12} className="mt-4">
                    <AboutCard data={item} />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
