import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/lib/integration/react";
import { StripeProvider } from "react-stripe-elements";
import { stripe_public_key } from "./Config/apiUrl";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <StripeScriptLoader
        uniqueId="myUniqueId"
        script="https://js.stripe.com/v3/"
        loader="Loading..."
      >
        <StripeProvider apiKey={stripe_public_key}> */}
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
      {/* </StripeProvider>
      </StripeScriptLoader> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
