import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";
import { URL } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Patch } from "../Axios/AxiosFunctions";
import { saveLoginUserData, updateUserData } from "../store/Actions/authAction";

const ChangePasswordModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authReducer?.access_token);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  //   handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = {
      passwordCurrent: oldPassword,
      password: newPassword,
      passwordConfirm: confirmPassword,
    };

    for (let key in params) {
      if (params[key] === "") return toast.warn("All fields are required");
    }

    if (newPassword !== confirmPassword)
      return toast.warn("New password and confirm password does not match");

    const url = URL(`users/updateMyPassword`);
    setLoading(true);
    const response = await Patch(url, params, header);
    setLoading(false);
    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data));
      setShow(false);
      toast.success("Password changed successfully");
    }
  };

  return (
    <Modal
      centered
      show={show}
      className="modal-Contaier payment-cards-modal"
      onHide={() => {
        setShow(false);
      }}
      backdrop={false}
    >
      <div className="row borderBottomColor pr-0 pl-0  ">
        <div className="col-md-12 d-flex flex-column justify-content-center text-center">
          <div className="close-icon-container" onClick={() => setShow(false)}>
            <CloseIcon className="c-p" />
          </div>

          {/* Form */}
          <Row md={12} className="signin-container">
            <Col md={12}>
              <h2>Change Password</h2>
            </Col>
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "30px",
              }}
            >
              <Col md={12} className="input input_styling">
                <div>
                  <input
                    className="mb-3"
                    type="password"
                    placeholder="Enter Old Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    className="mb-3"
                    type="password"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    className="mb-3"
                    type="password"
                    placeholder="Confirm Your Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={12}>
                <button
                  type="submit"
                  className="btn btn-blue-x"
                  disabled={loading}
                >
                  {loading ? "Please Wait..." : "Change Password"}
                </button>
              </Col>
            </form>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
