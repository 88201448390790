import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";
import StarIcon from "@mui/icons-material/Star";
const CarouselB = ({ item }) => {
  const caroRef = useRef();
  const handleLoop = (e) => {
    if (e?.index == 1) {
      if (caroRef.current !== null) {
        setTimeout(() => {
          caroRef?.current?.goTo(0);
        }, 4000);
      }
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];
  return (
    <>
      <Carousel
        breakPoints={breakPoints}
        pagination={false}
        onChange={(e) => handleLoop(e)}
        enableAutoPlay={true}
        autoPlaySpeed={4000}
        ref={caroRef}
        itemsToShow={5}
        showArrows={false}
      >
        {item?.map((data) => {
          return (
            <div className="text-center">
              <div>
                {Array(data?.rating)
                  ?.fill("")
                  ?.map(() => (
                    <StarIcon style={{ color: "#fed700" }} />
                  ))}
              </div>
              <div className="testi_desc">{data?.description}</div>
              <div className="testi_Name">{data?.userName}</div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default CarouselB;
