import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { signOutRequest, updateUserData } from "../store/Actions/authAction";
import { useHistory } from "react-router";

const CardForm = ({
  packageId,
  packageamountforApplePay,
  voucherCode,
  voucherResponse,
  voucherDiscountAmount,
  isMobileAppTaken,
  isMockExamTaken,
  mockupLength,
  addonAmount,
}) => {
  // --------------------------------------- Variables ----------------------------------------
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const stripe = useStripe();
  const elements = useElements();
  const [applePaySuccessMessage, setapplePaySuccessMessage] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // --------------------------------------- Handlers ----------------------------------------
  useEffect(() => {
    if (!stripe || !elements) {
      console.log("Stripe.js has not yet loaded.");
      return;
    }

    let clientSecret;
    let totalAmountWithOutCents =
      voucherResponse !== null
        ? voucherResponse?.discountedPrice / 100 + addonAmount
        : packageamountforApplePay;
    const pr = stripe.paymentRequest({
      // currency: "usd",
      // country: "US",
      currency: "gbp",
      country: "GB",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: `Apple Pay £${parseFloat(
          Math.round(totalAmountWithOutCents)
        ).toFixed(2)}`,
        amount: Math.round(totalAmountWithOutCents) * 100,
      },
    });

    pr.canMakePayment()
      .then((result) => {
        if (result) {
          console.log("result ", result);
          // => uncomment below to enable apple pay only
          if (result.applePay === false) {
            setPaymentRequest(undefined);
          } else {
            setPaymentRequest(pr);
          }
          // setPaymentRequest(pr);
        } else {
          setPaymentRequest(undefined);
        }
      })
      .catch((e) => console.log("apple error", e));

    pr.on("paymentmethod", async (e) => {
      // create and retrieve payment intent
      const intentUrl = URL("product/createSimplePaymentIntent");
      const intentResponse = await Post(
        intentUrl,
        {
          priceId: packageId,
          voucherCode: voucherCode,
          // addonAmount,
        },
        header
      );

      if (intentResponse !== undefined) {
        clientSecret = intentResponse?.data?.clientSecret;
        const confirmResponse = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        const { error, paymentIntent } = confirmResponse;

        if (error) {
          toast.error(error);
        } else {
          console.log(paymentIntent, "paymentIntent");
          e.complete("success");

          if (paymentIntent.status == "requires_action") {
            await stripe.confirmCardPayment(clientSecret);
            handleAppleApiCallOnLocalServer();
          }
          if (paymentIntent.status == "succeeded") {
            handleAppleApiCallOnLocalServer();
          }
        }
      }
    });
  }, [stripe, elements, packageamountforApplePay, voucherResponse]);

  const handleAppleApiCallOnLocalServer = async () => {
    // ! new url
    const mainUrl = URL(
      // `product/redirect/callback/apple-pay?priceId=${packageId}&isMobileAppTaken=${isMobileAppTaken}&isMockExamTaken=${isMockExamTaken}&mockupLength=${mockupLength}&totalPrice=${packageamountforApplePay}`
      `product/redirect/callback/apple-pay?priceId=${packageId}&totalPrice=${packageamountforApplePay}`
    );

    const response = await Get(mainUrl, token);
    if (response !== undefined) {
      toast.success("Subscription purchased successfully.");
      dispatch(updateUserData(response?.data?.data));
      history.replace("/dashboard");
    } else {
      dispatch(signOutRequest());
      history.push("/");
    }
  };

  return (
    <div className="applepay-container">
      {/* {applePaySuccessMessage !== "" && <p>{applePaySuccessMessage}</p>} */}
      {paymentRequest === null && (
        <p className="applepay-text">Please Wait...</p>
      )}
      {paymentRequest === undefined && (
        <p className="applepay-text">Your Device Does not support Apple Pay</p>
      )}
      {paymentRequest && (
        <div
          style={{
            width: "80% !important",
          }}
        >
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
            }}
          />
        </div>
      )}
      {/* <p>
        {" "}
        <a href="https://youtu.be/IhvtIbfDZJI" target="_blank">
          Watch a demo walkthrough
        </a>{" "}
      </p> */}
    </div>
  );
};

export default CardForm;
