import React, { useState } from 'react'

const Test = () => {
    const [state, setstate] = useState({})
    return (
        <div>
           <h1>hello</h1>
           <input onChange={e => setstate(e.target.value)}/>

{state}
        </div>
    )
}

export default Test
