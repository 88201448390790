import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Button } from "react-bootstrap";
import classes from "./PaymentModal.module.css";
import { RiCloseCircleLine } from "react-icons/ri";
import { BsFillCreditCardFill } from "react-icons/bs";
import { SiKlarna } from "react-icons/si";
import { TiVendorApple } from "react-icons/ti";
import { FaPlus } from "react-icons/fa";
// import Checkbox from "@mui/material/Checkbox";
import paymentMoreImage from "../../assets/Images/paymentMore.png";
import StripeCardField from "../StripeCardField";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { RadioGroup } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { URL, emailRegEx } from "../../Config/apiUrl";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { masterCardLogo, visaCardLogo } from "../../constant/imagePath";
import AvaiableCreditCards from "../AvaiableCreditCards";
import { toast } from "react-toastify";
import ApplePay from "../ApplePay";
import { useStripe } from "@stripe/react-stripe-js";
import { BiPurchaseTagAlt } from "react-icons/bi";

const PaymentModal = (props) => {
  const {
    show,
    handleClose,
    isLoading,
    HandleBuyPackage,
    packageId,
    packageamountforApplePay,
    setPackageAmountForApplePay,
    ourNewTotalAmount,
    setOurNewTotalAmount,
    selectedAmountPackage,
  } = props;
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const stripe = useStripe();

  const [isMobileAppTaken, setIsMobileAppTaken] = useState(false);
  const [isMockExamTaken, setIsMockExamTaken] = useState(false);
  const [mockupLength, setMockupLength] = useState(0);

  const [voucherCode, setVoucherCode] = useState("");
  const [buttonShow, setButtonShow] = useState("Card");

  const [addNewCard, setAddNewCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cards, setCards] = useState([]);

  const [isCheckingVoucher, setIsCheckingVoucher] = useState(false);
  const [voucherResponse, setVoucherResponse] = useState(null);

  const [klanraEmail, setKlanraEmail] = React.useState(user?.email);
  const [isKlarnaApiCall, setIsKlarnaApiCall] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachCardAndBuyLoading, setAttachCardAndBuyLoading] = useState(false);

  // Get Card List APi Start
  const getCardListApiUrl = URL("users/payment-methods");
  const getCardsListApiCall = async () => {
    setLoading(true);
    const responseData = await Get(getCardListApiUrl, token, false, dispatch);
    if (responseData !== undefined) {
      let data = [];
      responseData?.data?.data?.map((item) => {
        data.push({
          id: item.id,
          cardName: item.card.brand,
          cardImage: item.card.brand == "visa" ? visaCardLogo : masterCardLogo,
          last4digits: item.card.last4,
          expirationMonth: item.card.exp_month,
          expirationYear: item.card.exp_year,
        });
      });
      setCards(data);
    }
    setLoading(false);
  };
  // Get Card List APi End
  // Voucher Verification Api Start
  const handleVoucherVerification = async () => {
    if (!voucherCode) return;

    const voucherUrl = URL("voucher/check-voucher-description");
    const params = {
      voucherCode: voucherCode,
      priceId: packageId,
    };
    setIsCheckingVoucher(true);
    const voucherResponse = await Post(voucherUrl, params, header);
    setIsCheckingVoucher(false);

    if (voucherResponse !== undefined) {
      toast.success("Voucher applied successfully.");

      setVoucherResponse(voucherResponse?.data?.data);
    } else {
      setVoucherResponse(null);
    }
  };
  // Voucher Verification Api End
  // Attach Payment Method Api Start
  const attachPayCardApiCall = async (id) => {
    const attachPayCardApiUrl = URL("users/attach-payment-methods");
    setAttachCardAndBuyLoading(true);
    const params = {
      pmId: id,
    };
    const attachPayCardApiData = await Post(
      attachPayCardApiUrl,
      params,
      header
    );

    if (attachPayCardApiData !== undefined) {
      let data = [];
      attachPayCardApiData?.data?.data?.map((item) => {
        data.push({
          id: item.id,
          cardName: item.card.brand,
          cardImage: item.card.brand == "visa" ? visaCardLogo : masterCardLogo,
          last4digits: item.card.last4,
          expirationMonth: item.card.exp_month,
          expirationYear: item.card.exp_year,
        });
      });
      setCards(data);
      setAddNewCard(false);
      await HandleBuyPackage(
        id,
        voucherCode,
        isMobileAppTaken,
        isMockExamTaken,
        mockupLength,
        ourNewTotalAmount - selectedAmountPackage
      );
      // toast.success(
      //   "You have successfully added a new card, now kindly select a card and pay."
      // );
    }
    setAttachCardAndBuyLoading(false);
  };

  // Attach Payment Method Api End
  // Detach Payment Method Api Start
  const detachThisCard = async (id) => {
    const detachCardURL = URL("users/detach-payment-methods");
    setLoading(true);
    const params = {
      pmId: id,
    };
    const detachCardApiResponse = await Post(detachCardURL, params, header);
    if (detachCardApiResponse != undefined) {
      let data = [];
      detachCardApiResponse?.data?.data?.map((item) => {
        data.push({
          id: item.id,
          cardName: item.card.brand,
          cardImage: item.card.brand == "visa" ? visaCardLogo : masterCardLogo,
          last4digits: item.card.last4,
          expirationMonth: item.card.exp_month,
          expirationYear: item.card.exp_year,
        });
      });
      setCards(data);
      toast.success("Payment card successfully removed.");
    }
    setLoading(false);
  };
  // Detach Payment Method Api Start

  // handle Klarna Api Start
  const handleKlarna = async () => {
    const intentUrl = URL("product/createPaymentIntent");
    let clientSecret;
    setIsKlarnaApiCall(true);
    const intentResponse = await Post(
      intentUrl,
      {
        priceId: packageId,
        voucherCode: voucherCode,
        // addonAmount: ourNewTotalAmount - selectedAmountPackage,
        // isMobileAppTaken,
        // isMockExamTaken,
        // mockupLength,
      },
      header
    );
    if (intentResponse !== undefined) {
      localStorage.setItem("_cmbopu", packageId);
      // localStorage.setItem("_cmbopu1", isMobileAppTaken);
      // localStorage.setItem("_cmbopu2", isMockExamTaken);
      // localStorage.setItem("_cmbopu3", mockupLength);
      // localStorage.setItem("_cmbopu4", ourNewTotalAmount);
      clientSecret = intentResponse?.data?.clientSecret;

      const klarnaData = await stripe.confirmKlarnaPayment(clientSecret, {
        payment_method: {
          billing_details: {
            email: klanraEmail, //toDO
            address: {
              country: "GB",
            },
          },
        },
        return_url: `https://${window.location.host}/thankyou?priceId=${packageId}&isMobileAppTaken=${isMobileAppTaken}&isMockExamTaken=${isMockExamTaken}&mockupLength=${mockupLength}`,
      });

      const { error: stripeError } = klarnaData;

      if (stripeError) {
        toast.error(stripeError);
        localStorage.removeItem("_cmbopu");
      }
      setIsKlarnaApiCall(false);
    }
  };
  // handle Klarna Api End

  const RenderCardComponent = () => {
    return (
      <>
        {loading ? (
          <RenderSkeletons />
        ) : (
          <>
            {cards.length == 0 || addNewCard ? (
              <>
                {addNewCard && (
                  <div className={[classes.cardHeader].join(" ")}>
                    <div
                      className={[classes.addCardBtn].join(" ")}
                      onClick={() => setAddNewCard(false)}
                    >
                      <MdKeyboardArrowLeft size={20} color={"#000"} />
                      <span
                        style={{
                          marginLeft: 0,
                        }}
                      >
                        Back
                      </span>
                    </div>
                  </div>
                )}
                <StripeCardField
                  AttachCardHandler={attachPayCardApiCall}
                  loading={attachCardAndBuyLoading}
                />
              </>
            ) : (
              <>
                <div className={[classes.cardHeader].join(" ")}>
                  <span>All Debit/Credit Card</span>

                  <div
                    className={[classes.addCardBtn].join(" ")}
                    onClick={() => setAddNewCard(true)}
                  >
                    <FaPlus size={17} color={"#3792ec"} />
                    <span>ADD A NEW CARD</span>
                  </div>
                </div>
                <RadioGroup
                  aria-label="availableCard"
                  name="availableCard"
                  value={paymentMethod}
                >
                  {cards?.length > 0 &&
                    cards?.map((card, index) => {
                      return (
                        <AvaiableCreditCards
                          key={index}
                          availableCard={card}
                          paymentMethod={paymentMethod}
                          setPaymentMethod={setPaymentMethod}
                          handleDetachCard={detachThisCard}
                        />
                      );
                    })}
                </RadioGroup>
                {cards?.length > 0 && (
                  <Col md={12}>
                    <div
                      className={[classes.btnMainDiv, classes.cardBtn].join(
                        " "
                      )}
                      onClick={() =>
                        isLoading == false &&
                        paymentMethod !== "" &&
                        HandleBuyPackage(
                          paymentMethod,
                          voucherCode,
                          isMobileAppTaken,
                          isMockExamTaken,
                          mockupLength,
                          ourNewTotalAmount - selectedAmountPackage
                        )
                      }
                    >
                      <BiPurchaseTagAlt size={20} color={"#3792ec"} />
                      <span>
                        {loading ? "Purchasing..." : "Purchase Package"}
                      </span>
                    </div>
                  </Col>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const RenderKlarnaComponent = () => {
    return (
      <div className={[classes.klarnaContainer].join(" ")}>
        <input
          type="email"
          className="speacial_input"
          placeholder="Enter Email Address"
          disabled
          value={klanraEmail}
          onChange={(e) => setKlanraEmail(e.target.value)}
        />
        <div
          className={[classes.btnMainDiv, classes.klarnaBtn].join(" ")}
          onClick={() => {
            if (isKlarnaApiCall) return;
            handleKlarna();
          }}
        >
          <SiKlarna size={20} color={"#000"} />
          <span>Pay with Klarna</span>
        </div>
      </div>
    );
  };
  const RenderApplePayComponent = () => {
    return (
      <ApplePay
        voucherCode={voucherCode}
        voucherResponse={voucherResponse}
        packageId={packageId}
        // packageamountforApplePay={
        //   voucherResponse
        //     ? voucherResponse?.discountedPrice / 100
        //     : packageamountforApplePay
        // }
        packageamountforApplePay={packageamountforApplePay}
        isMobileAppTaken={isMobileAppTaken}
        isMockExamTaken={isMockExamTaken}
        mockupLength={mockupLength}
        addonAmount={packageamountforApplePay - selectedAmountPackage}
      />
    );
  };

  useEffect(() => {
    getCardsListApiCall();
  }, [show]);

  const HandleCloseModal = () => {
    setVoucherResponse(null);
    setVoucherCode("");
    handleClose();
  };

  return (
    <Modal
      centered
      show={show}
      className={[classes.paymentCardModal].join(" ")}
      dialogClassName={[classes.paymentModalDialog].join(" ")}
      contentClassName={[classes.paymentModalcontent].join(" ")}
      onHide={() => {
        setMockupLength(0);
        HandleCloseModal();
      }}
    >
      <Container fluid className={"px-0"}>
        <Row className={`${[classes.modalHeader].join(" ")} g-0 m-0`}>
          <Col md={12}>
            <div className={classes.div_between}>
              <h2 className={[classes.headerTitle].join(" ")}>
                Payment Methods{" "}
              </h2>
              {ourNewTotalAmount > 0 && (
                <h2 className={[classes.headerTitle].join(" ")}>
                  Total:{" "}
                  {voucherResponse == null ? (
                    `£${parseFloat(ourNewTotalAmount).toFixed(2)}`
                  ) : (
                    <>
                      <h2 className={classes?.lineThrough}>
                        £{parseFloat(ourNewTotalAmount).toFixed(2)}
                      </h2>{" "}
                      £
                      {parseFloat(
                        voucherResponse?.discountedPrice / 100 +
                          (ourNewTotalAmount - selectedAmountPackage)
                      ).toFixed(2)}
                    </>
                  )}
                </h2>
              )}
            </div>

            <RiCloseCircleLine
              size={30}
              color={"white"}
              className={[classes.closeIcon].join(" ")}
              onClick={() => {
                HandleCloseModal();
              }}
            />
          </Col>
        </Row>
        <Row className={`${[classes.modalBody].join(" ")} g-0 m-0`}>
          {/* <Col md={6} className={[classes.left].join(" ")}>
            <h3 className={[classes.bodyTitle, classes.leftTitle].join(" ")}>
              Enhance Your Learning Experience
            </h3>
            <div className={[classes.addApplicationContainer].join(" ")}>
              <div className={[classes.checkBoxContainer].join(" ")}>
                <input
                  className={`form-check-input ${[classes.checkBox].join(" ")}`}
                  type="checkbox"
                  id="isMobileAppTaken"
                  name="isMobileAppTaken"
                  value={isMobileAppTaken}
                  onChange={(e) => {
                    setIsMobileAppTaken(e?.target?.checked);
                    if (e?.target?.checked) {
                      setOurNewTotalAmount((p) => +p + 40);
                      setPackageAmountForApplePay((p) => +p + 40);
                    } else {
                      setOurNewTotalAmount((p) => +p - 40);
                      setPackageAmountForApplePay((p) => +p - 40);
                    }
                  }}
                />
                <p>
                  Add Pre Reg Master <b>iOS/Android App</b> containing{" "}
                  <b>1000+ Questions</b> with detailed explanations.
                </p>
              </div>

              <div className={[classes.priceContainer].join(" ")}>
                <span>Usually £60</span>
                <span>£40 TODAY ONLY</span>
              </div>

             
              <img src={paymentMoreImage} />
            </div>

            <div className={[classes.checkBoxContainer].join(" ")}>
              <input
                className={`form-check-input ${[classes.checkBox].join(" ")}`}
                type="checkbox"
                id="mockExam"
                name="mockExam"
                value={isMockExamTaken}
                onChange={(e) => {
                  setIsMockExamTaken(e?.target?.checked);
                  if (e?.target?.checked) {
                    setOurNewTotalAmount((p) => +p + mockupLength * 25);
                    setPackageAmountForApplePay((p) => +p + mockupLength * 25);

                    if (mockupLength === 0) {
                      setMockupLength((p) => p + 1);
                      setOurNewTotalAmount((p) => +p + 25);
                      setPackageAmountForApplePay((p) => +p + 25);
                    }
                  } else {
                    setOurNewTotalAmount((p) => +p - mockupLength * 25);
                    setPackageAmountForApplePay((p) => +p - mockupLength * 25);
                  }
                }}
              />
              <p>
                Add online <b>mock exam(s)</b> for a realistic practice
                environment{" "}
              </p>
            </div>
            <div className={[classes.priceContainer].join(" ")}>
              <span>Usually £30</span>
              <span>£25 EACH TODAY ONLY</span>
            </div>

            
            <div className={[classes.counterContainer].join(" ")}>
              <span
                className={
                  (!isMockExamTaken || mockupLength == 0) &&
                  classes.cursorNoDrop
                }
                onClick={() => {
                  if (!isMockExamTaken) return;
                  if (mockupLength == 0) return;
                  setMockupLength((p) => p - 1);
                  setOurNewTotalAmount((p) => +p - 25);
                  setPackageAmountForApplePay((p) => +p - 25);
                }}
              >
                -
              </span>
              <span
                className={[
                  classes.countNumberText,
                  !isMockExamTaken && classes.cursorNoDrop,
                ].join(" ")}
              >
                {mockupLength}
              </span>

              <span
                className={
                  (!isMockExamTaken || mockupLength == 3) &&
                  classes.cursorNoDrop
                }
                onClick={() => {
                  if (!isMockExamTaken) return;
                  if (mockupLength == 3) return;
                  setMockupLength((p) => p + 1);
                  setOurNewTotalAmount((p) => +p + 25);
                  setPackageAmountForApplePay((p) => +p + 25);
                }}
              >
                +
              </span>
            </div>
          </Col> */}
          <Col md={12} className={[classes.right].join(" ")}>
            <h3 className={[classes.voucherHeading].join(" ")}>
              Discount Voucher
            </h3>
            <div className={`mb-3 ${[classes.checkBoxContainer].join(" ")}`}>
              <input
                className="mb-3 w-100 form-control"
                type="text"
                name="text"
                id="username"
                placeholder="Enter voucher code"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e?.target?.value)}
                autoComplete="off"
              />
              <Button
                className={`btn btn-primary ${[classes.voucherBtn].join(" ")}`}
                onClick={handleVoucherVerification}
                disabled={isCheckingVoucher}
              >
                {isCheckingVoucher ? "Checking" : "Apply"}
              </Button>
            </div>

            <h3 className={[classes.bodyTitle].join(" ")}>Payment Type</h3>
            <div className={[classes.btnsContainer].join(" ")}>
              <div
                className={[
                  classes.btnMainDiv,
                  classes.cardBtn,
                  buttonShow == "Card" && classes.activeBtn,
                ].join(" ")}
                onClick={() => setButtonShow("Card")}
              >
                <BsFillCreditCardFill size={20} color={"#3792ec"} />
                <span>Card</span>
              </div>

              <div
                className={[
                  classes.btnMainDiv,
                  classes.klarnaBtn,
                  buttonShow == "Klanra" && classes.activeBtn,
                ].join(" ")}
                onClick={() => setButtonShow("Klanra")}
              >
                <SiKlarna size={20} color={"#000"} />
                <span>Klarna</span>
              </div>
              <div
                className={[
                  classes.btnMainDiv,
                  classes.AppleBtn,
                  buttonShow == "Apple" && classes.applePayActiveBtn,
                ].join(" ")}
                onClick={() => setButtonShow("Apple")}
              >
                <TiVendorApple size={20} color={"#fff"} />
                <span>Pay</span>
              </div>
            </div>

            {buttonShow == "Card" ? (
              <RenderCardComponent />
            ) : buttonShow == "Klanra" ? (
              <RenderKlarnaComponent />
            ) : (
              <RenderApplePayComponent />
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default PaymentModal;

const RenderSkeletons = () => {
  return [1, 2, 3, 4].map((item, i) => {
    return (
      <div
        className="mt-1 mb-2 ml-1 d-flex align-content-center justify-content-center"
        key={i}
      >
        <Skeleton animation="wave" style={{ width: "95%", height: "50px" }} />
      </div>
    );
  });
};
