import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = ({placeholderColor,placholderText="Search Cardiovascular System..."}) => {
  console.log(placeholderColor)
    return (
        <>
             <div className="sidebar_search">
            <input type="text" placeholder={placholderText}
            style={{backgroundColor:placeholderColor}}/>
            <SearchIcon/>
          </div>
        </>
    )
}

export default SearchInput
