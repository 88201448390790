import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SubFooter = () => {
  return (
    <Container fluid className="sub-footer col-md-12">
      <Link to="/terms-and-condition">
        <p>T&Cs</p>
      </Link>
      <div style={{ marginTop: "-15px" }}>
        <p style={{ color: "white", margin: "0px 10px", fontSize: "30px" }}>
          .
        </p>
      </div>
      <Link to="/privacy-policy">
        <p>Privacy Policy</p>
      </Link>
    </Container>
  );
};

export default SubFooter;
