import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NoPreviewAvailable } from "../constant/imagePath";
import Header from "./Header";
import PackageHeader from "./PackageHeader";

const MobileRestrictedComponent = () => {
  const history = useHistory();
  const token = useSelector((state) => state?.authReducer?.access_token);

  return (
    <>
      <Container fluid className="MobileRestrictedContainer">
        {token !== "" ? <PackageHeader /> : <Header />}
        <div className="mobile-section-div">
          <div className="noPreviewContainer">
            <Image
              src={NoPreviewAvailable}
              className="col-md-12 noPreviewImg"
            />
          </div>

          <p className="col-md-12 mobileRestrictedText">
            For the best learning experience, please access Pre Reg Master on a
            desktop device.
          </p>
          <div
            className="free-div-mbl"
            onClick={() => history.push(token ? "/packages" : "/signup")}
          >
            <span>Unlock All Videos</span>
          </div>
        </div>
      </Container>
    </>
  );
};

export default MobileRestrictedComponent;
