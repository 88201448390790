import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  SET_SECTION,
  UPDATE_USER,
  SET_HOME_SECTION,
} from "../Actions/actionType";

const INITIAL_STATE = {
  access_token: "",
  isLogin: false,
  user: null,
  mode: "light",
  section: [],
  homePageSection: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action?.payload?.data?.user,
        isLogin: true,
        access_token: action.payload?.token,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action?.payload,
      };
    case SET_SECTION:
      return {
        ...state,
        section: action?.payload,
      };
    case SET_HOME_SECTION:
      return {
        ...state,
        homePageSection: action?.payload,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        access_token: "",
        isLogin: false,
        user_type: "",
        user: null,
        section: [],
      };
    default:
      return state;
  }
};
