import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SearchInput from "./SearchInput";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Image } from "react-bootstrap";
import { logo, user, logoNew } from "../constant/imagePath";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { URL } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { signOutRequest } from "../store/Actions/authAction";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ChangePasswordModal from "./ChangePasswordModal";
const HeaderSigned = ({
  hide = false,
  setSearchText,
  searchText,
  responseArray,
  handleSelect,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = useSelector((state) => state?.authReducer?.access_token);
  const userData = useSelector((state) => state?.authReducer?.user);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const postCraftmenData = URL(`users/logout`);
  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const handleLogout = async () => {
    // const data = {
    //   fcmToken: 786,
    // };
    // const response = await Post(postCraftmenData, data, header);
    // if (response?.status == 200) {
    dispatch(signOutRequest());
    history.push("/");
    // }
    handleClose();
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    var values = name !== undefined ? name.split(" ") : [];
    var fisrtNameAlphabet = values[0] ? values[0].charAt(0) : "";
    var lastNameAlphabet = values[1] ? values[1].charAt(0) : "";
    return {
      sx: {
        bgcolor: stringToColor(name !== undefined ? name : "user"),
      },
      children: `${fisrtNameAlphabet.toUpperCase()}${lastNameAlphabet.toUpperCase()}`,
    };
  }
  const userName = userData?.name;
  return (
    <>
      <div className="signed_header package_signed_header">
        <div>
          <Image src={logoNew} style={{ width: "50px" }} />
        </div>

        <div className="menu_main d-flex a-center" onClick={handleClick}>
          {/* <Image className="user_img" src={user} /> */}
          <Stack direction="row" spacing={2}>
            <Avatar {...stringAvatar(userName)} />
          </Stack>
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {userData?.name}
          </Button>
          <ArrowDropDownIcon />
        </div>
      </div>
      <Menu
        className="menu_cus"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          className="menu-item-x"
          onClick={() => {
            setIsChangePasswordModalOpen(true);
            handleClose();
          }}
        >
          Change Password
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <ChangePasswordModal
        show={isChangePasswordModalOpen}
        setShow={setIsChangePasswordModalOpen}
      />
    </>
  );
};

export default HeaderSigned;
